import { AnimatePresence } from "framer-motion";
import PropTypes from "prop-types";
import { useEffect, useRef } from "react";

import { InventoryQuestion } from "@features/interest-inventory/components/question";
import { SurveyCompleted } from "@features/interest-inventory/components/survey.completed";
import { RateCareersView } from "@features/interest-inventory/views/rate-careers.view";
import { Case, ErrorToast, Loader, ProgressBar, Switch } from "@features/ui";

import { useCareerMatches } from "@features/career-matches/api";
import { useSetQuestionAnswer } from "@features/interest-inventory/api/use-set-question-answer";
import { useSetUserCareerRating } from "@features/interest-inventory/api/use-set-user-career-rating";
import { useUserCareerRatings } from "@features/interest-inventory/api/use-user-career-ratings";
import {
  NUM_INITIAL_QUESTIONS,
  NUM_RATINGS_REQUIRED,
  SURVEY_STATES,
} from "@features/interest-inventory/hooks/use-survey-state";
import { useUserPreliminaryCareers } from "../api/use-user-preliminary-careers";

import { PreliminaryCareersView } from "../views/preliminary-careers.view";
import { QuestionLoader } from "./question.loader";

import "./survey.scss";

export const Survey = ({
  surveyId,
  surveyState,
  onQuestionAnswered,
  onSurveyCompleted,
}) => {
  const {
    state: {
      name: surveyStateName,
      context: {
        currentSurveyQuestionIndex,
        currentCareerMatchIndex,
        surveyQuestions,
      },
    },
    progress,
    preliminaryCareersFetched,
    careerMatchesFetched,
    careerRatingsFetched,
    hasPreliminaryCareer,
    preliminaryCareerAnswered,
    surveyQuestionAnswered,
    careerRatingAnswered,
  } = surveyState;

  const ref = useRef();

  const {
    mutateAsync: setAnswer,
    isPending: isSetAnswerPending,
    isError: isSetAnswerError,
  } = useSetQuestionAnswer(surveyId);

  const { data: careerMatches, isFetching: isCareerMatchesFetching } =
    useCareerMatches({
      enabled: surveyStateName === SURVEY_STATES.loadingCareerMatches,
      limit: NUM_RATINGS_REQUIRED,
    });

  const { data: userCareerRatings, isFetching: isUserCareerRatingsFetching } =
    useUserCareerRatings();

  const {
    data: userPreliminaryCareers,
    isFetching: isUserPreliminaryCareersFetching,
  } = useUserPreliminaryCareers();

  useEffect(() => {
    if (!isUserPreliminaryCareersFetching) {
      preliminaryCareersFetched(userPreliminaryCareers);
    }
  }, [isUserPreliminaryCareersFetching, userPreliminaryCareers]);

  useEffect(() => {
    if (!isUserCareerRatingsFetching) {
      careerRatingsFetched(userCareerRatings);
    }
  }, [isUserCareerRatingsFetching, userCareerRatings]);

  useEffect(() => {
    if (
      surveyStateName === SURVEY_STATES.loadingCareerMatches &&
      !isCareerMatchesFetching
    ) {
      careerMatchesFetched(careerMatches);
    }
  }, [isCareerMatchesFetching, careerMatches, surveyStateName]);

  const {
    mutateAsync: setRating,
    isPending: isSetRatingPending,
    isError: isSetRatingError,
  } = useSetUserCareerRating({
    careerId: careerMatches?.[currentCareerMatchIndex]?.id,
  });

  useEffect(() => {
    if (surveyStateName === SURVEY_STATES.complete) {
      onSurveyCompleted?.();
    }
  }, [surveyStateName]);

  const onAnswerQuestion = async ({ id: questionId, answer }) => {
    await setAnswer({ questionId, answer });
    surveyQuestionAnswered({ id: questionId, answer });
    onQuestionAnswered?.();
    ref.current.focus();
  };

  const onCareerRated = async (careerRating) => {
    await setRating(careerRating.rating);
    careerRatingAnswered(careerRating);
  };

  return (
    <div
      className="interest-inventory-survey"
      ref={ref}
      tabIndex={-1}
      aria-atomic
      aria-busy={isSetAnswerPending || isSetRatingPending}
      aria-live="polite"
    >
      <Loader show={isSetAnswerPending} text="Saving answer..." />
      <Loader show={isSetRatingPending} text="Saving rating..." />
      <ErrorToast open={isSetAnswerError || isSetRatingError} />
      <AnimatePresence mode="popLayout">
        <Switch option={surveyStateName}>
          <Case value={SURVEY_STATES.initializing}>
            <QuestionLoader />
          </Case>
          <Case value={[SURVEY_STATES.askPrelim, SURVEY_STATES.getPrelim]}>
            <PreliminaryCareersView
              surveyStateName={surveyStateName}
              onAnswerInitialQuestion={({ answer }) =>
                hasPreliminaryCareer(answer)
              }
              onAnswerPreliminaryCareer={(answer) => {
                preliminaryCareerAnswered(answer);
                onQuestionAnswered?.();
              }}
            />
          </Case>
          <Case value={SURVEY_STATES.survey}>
            <InventoryQuestion
              key={surveyQuestions?.[currentSurveyQuestionIndex]?.id}
              value={surveyQuestions?.[currentSurveyQuestionIndex]}
              onAnswer={onAnswerQuestion}
            />
          </Case>
          <Case
            value={[
              SURVEY_STATES.loadingCareerMatches,
              SURVEY_STATES.ratingCareers,
            ]}
          >
            <RateCareersView
              career={careerMatches?.[currentCareerMatchIndex]}
              isLoading={isCareerMatchesFetching}
              onCareerRated={onCareerRated}
            />
          </Case>
          <Case value={SURVEY_STATES.complete}>
            <SurveyCompleted
              questions={surveyQuestions}
              userCareerRatings={userCareerRatings}
            />
          </Case>
        </Switch>
      </AnimatePresence>
      {surveyStateName !== SURVEY_STATES.complete &&
        surveyStateName !== SURVEY_STATES.initializing && (
          <ProgressBar
            min={0}
            max={
              surveyQuestions?.length +
              NUM_RATINGS_REQUIRED +
              NUM_INITIAL_QUESTIONS
            }
            value={progress}
          />
        )}
    </div>
  );
};

Survey.displayName = "Interest Survey";

Survey.propTypes = {
  surveyId: PropTypes.number,
  surveyState: PropTypes.object,
  onQuestionAnswered: PropTypes.func,
  onSurveyCompleted: PropTypes.func,
  showIntroduction: PropTypes.bool,
};
