import PropTypes from "prop-types";
import { useCallback, useEffect, useMemo, useState } from "react";

import {
  CareerItem,
  PreliminaryCareerMatchToast,
} from "@features/career-matches/components";
import { ErrorToast, ListItemLoader } from "@features/ui";

import { useBreakpoints } from "@hooks/use-breakpoints";
import { EmptyArray } from "@lib/app.helpers";

export const CareerMatchesList = ({
  careerMatches,
  careerSelected,
  isError,
  isLoading,
  onCareerClick,
  preliminaryCareers,
}) => {
  const { careerId, submittedAt } = careerSelected;

  const { isMobile } = useBreakpoints();

  const [careerMatchesToDisplay, setCareerMatchesToDisplay] = useState([]);
  const [isPreliminaryVisibleInOrder, setIsPreliminaryVisibleInOrder] =
    useState(true);

  const sortedCareers = useMemo(
    () =>
      careerMatches?.toSorted((a, b) => {
        // keep hidden careers at the bottom
        if (a.hidden && !b.hidden) {
          return 1;
        }
        if (!a.hidden && b.hidden) {
          return -1;
        }

        return a.orderNum - b.orderNum;
      }) ?? [],
    [careerMatches],
  );

  const exactMatchCodes = useMemo(() => {
    if (sortedCareers?.length > 0 && preliminaryCareers?.length > 0) {
      const preliminaryCareersByOnetCode = preliminaryCareers.reduce(
        (acc, preliminaryCareer) => acc.add(preliminaryCareer.onetCode),
        new Set(),
      );

      return sortedCareers.reduce(
        (acc, career) =>
          preliminaryCareersByOnetCode.has(career.onetCode)
            ? acc.add(career.onetCode)
            : acc,
        new Set(),
      );
    }

    return new Set();
  }, [sortedCareers, preliminaryCareers]);

  const { closeMatchCareers, closeMatchCodes } = useMemo(() => {
    // closeMatchCareers to display the correct notification when found or not close matches.
    // closeMatchCodes to validate if a career is a close match
    const initialValue = {
      closeMatchCareers: [],
      closeMatchCodes: new Set(),
    };
    if (sortedCareers?.length > 0 && preliminaryCareers?.length > 0) {
      return preliminaryCareers.reduce((acc, preliminaryCareer) => {
        // Do not include exact matches to avoid confusions
        if (!exactMatchCodes.has(preliminaryCareer.onetCode)) {
          // If an unsupported preliminary career has supported related careers
          // then we can say it has close matches and we add them to the Set for later validations.
          // Otherwise, for an unsupported preliminary career with unsupported related careers
          // we only set the `hasCloseMatch` as false indicating that we are doing our best.
          acc.closeMatchCareers.push({
            ...preliminaryCareer,
            hasCloseMatch: preliminaryCareer.related?.length > 0,
          });
          if (preliminaryCareer.related)
            for (const c of preliminaryCareer.related)
              acc.closeMatchCodes.add(c.onetCode);
        }
        return acc;
      }, initialValue);
    }

    return initialValue;
  }, [sortedCareers, preliminaryCareers, exactMatchCodes]);

  useEffect(() => {
    if (!careerMatches) {
      return;
    }

    // If a preliminary career match isn't part of the first page of results but
    // can actually fit within the view (which is _very_ unlikely in the real
    // world), go ahead and just force it out of the flow. This will avoid
    // displaying an order like 1, 2, 3, 4, 82, 5, 6, 7, ...
    const limit = isMobile ? 5 : 10;
    if (
      careerMatches
        .slice(0, limit)
        .some(
          (career) =>
            career.orderNum > limit && exactMatchCodes.has(career.onetCode),
        )
    ) {
      setIsPreliminaryVisibleInOrder(false);
    }
  }, [careerMatches]);

  useEffect(() => {
    if (isPreliminaryVisibleInOrder) {
      // try to display everything "properly" sorted
      setCareerMatchesToDisplay(sortedCareers);
    } else {
      // if any preliminary careers aren't visible using the "properly"
      // sorted list, fall back to the ordering we originally received
      setCareerMatchesToDisplay(careerMatches);
    }
  }, [isPreliminaryVisibleInOrder, sortedCareers, careerMatches]);

  const getMatchType = useCallback(
    (onetCode) => {
      if (exactMatchCodes.has(onetCode)) {
        return "exact";
      } else if (closeMatchCodes.has(onetCode)) {
        return "related";
      }
    },
    [closeMatchCodes, exactMatchCodes],
  );

  if (isLoading) {
    return EmptyArray(5).map((_, index) => (
      <ListItemLoader key={index}></ListItemLoader>
    ));
  }

  const renderItems = [];

  if (closeMatchCareers.length > 0) {
    renderItems.push(
      <PreliminaryCareerMatchToast
        key="close-matches-notification"
        careers={closeMatchCareers}
      />,
    );
  }

  renderItems.push(
    careerMatchesToDisplay.map((career, arrayIndex) => (
      <CareerItem
        key={`career.${career.transfrId}`}
        career={career}
        hidden={career?.hidden}
        index={career.orderNum ?? arrayIndex + 1}
        matchType={getMatchType(career.onetCode)}
        onClick={onCareerClick}
        onVisibilityCheck={(visible) => {
          if (!visible && exactMatchCodes.has(career.onetCode)) {
            setIsPreliminaryVisibleInOrder(false);
          }
        }}
        outOfFlow={
          !isPreliminaryVisibleInOrder && exactMatchCodes.has(career.onetCode)
        }
        selected={careerId === career.id && !!submittedAt}
      />
    )),
  );

  if (isError) {
    renderItems.push(
      <ErrorToast open message="Something went wrong. Please try again." />,
    );
  }

  return renderItems;
};

CareerMatchesList.displayName = "Career Matches List";

CareerMatchesList.propTypes = {
  careerMatches: PropTypes.arrayOf(PropTypes.object),
  careerSelected: PropTypes.object,
  isError: PropTypes.bool,
  isLoading: PropTypes.bool,
  onCareerClick: PropTypes.func,
};
