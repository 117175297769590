import { CopyrightFooter } from "@features/ui";
import { Form } from "./components/form";
import { HeaderImage } from "./components/header-image";

import { useAuth } from "@hooks/use-auth";
import { useLocalStorage } from "@hooks/use-local-storage";
import { useUpdateUserAgreement } from "@pages/terms-and-conditions/api/use-update-user-agreement";

import headerImageUrl from "@images/trek-logo.large.png?url";

import "./index.scss";

export const TermsAndConditionsPage = () => {
  const { navigateHome, user } = useAuth();
  const [, setUser] = useLocalStorage("user");

  const { mutate: updateUserAgreement, error } = useUpdateUserAgreement();

  const submitAgreement = (data) => {
    updateUserAgreement(data, {
      onSuccess: () => {
        setUser({
          ...user,
          // the value we set here is largely arbitrary, it just needs to be
          // set to _something_ in order to navigate past this screen. the
          // actual timestamp is set appropriately on the backend and will be
          // returned on subsequent logins.
          agreementAcceptedAt: new Date(),
        });
        navigateHome();
      },
    });
  };

  return (
    <div className="terms-and-conditions-page">
      <HeaderImage imageUrl={headerImageUrl} />
      <Form onSubmit={submitAgreement} error={error} />
      <CopyrightFooter />
    </div>
  );
};
