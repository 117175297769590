import PropTypes from "prop-types";
import { useEffect, useMemo, useState } from "react";

import { Button } from "@transfr-inc/dashboard-components/forms";

import { CareerMatchesList } from "@features/career-matches/components/career-matches.list";
import { Card, CardBody, CardFooter, CardHeader, Loader } from "@features/ui";
import { CareerMatchesStartOverConfirmationModal } from "@pages/journey-map/views/career-matches-start-over-confirmation.modal";

import { A11yAlertAnnouncer } from "@features/a11y";
import { useCareerPlan } from "@features/career-details/api/use-career-plan";
import { useCareerMatches } from "@features/career-matches/api";
import { useUserPreliminaryCareers } from "@features/interest-inventory/api/use-user-preliminary-careers";
import { useBreakpoints } from "@hooks/use-breakpoints";
import { buttonTracking } from "@lib/tracking.helpers";
import { useStartOver } from "@pages/journey-map/api/use-start-over";

import "./index.scss";

export const CareerMatches = ({ onCareerSelected, onClose, onStartOver }) => {
  const [fetchingNextPage, setFetchingNextPage] = useState();
  const [openConfirmationModal, setOpenConfirmationModal] = useState();
  const { isMobile } = useBreakpoints();

  const pageSize = useMemo(() => (isMobile ? 5 : 10), [isMobile]);

  const { data: careerPlan } = useCareerPlan();
  const {
    data: careerMatches,
    isError: careerMatchesErrored,
    isFetching: fetchingCareerMatches,
    fetchNextPage,
    hasNextPage,
  } = useCareerMatches({
    limit: pageSize,
  });
  const { data: preliminaryCareers, isFetching: fetchingPreliminaryCareers } =
    useUserPreliminaryCareers({ includeRelatedCareers: true });

  const { isStartingOver, startOver } = useStartOver();

  const onCareerClick = (career, index) => {
    onCareerSelected(career, index);
  };

  const closeConfirmationModal = () => setOpenConfirmationModal();

  const onConfirmStartOver = async () => {
    await startOver({ restartInterestInventory: true });
    closeConfirmationModal();
    onStartOver?.();
  };

  const onNextPage = () => {
    fetchNextPage();
    setFetchingNextPage(true);
  };

  useEffect(() => {
    // Show skeleton loader when refreshing the careers list
    // This to avoid flickering in the list when a career has set to hide in details page
    if (fetchingNextPage && !fetchingCareerMatches) {
      setFetchingNextPage();
    }
  }, [fetchingCareerMatches]);

  return (
    <>
      <Card className="career-matches-card">
        <CardHeader
          title={<h1 tabIndex={-1}>Career Matches</h1>}
          toolbar={
            <Button
              onClick={onClose}
              icon={["fa-regular", "xmark"]}
              title="close"
              {...buttonTracking("career-matches", "close")}
            />
          }
        >
          <div className="subheader">
            Based on your likes and dislikes we&apos;ve ranked careers we think
            would be a good fit for you.
          </div>
        </CardHeader>
        <CardBody className="career-matches-card-body">
          <CareerMatchesList
            careerMatches={careerMatches}
            preliminaryCareers={preliminaryCareers}
            careerSelected={careerPlan}
            isLoading={
              fetchingPreliminaryCareers ||
              (fetchingCareerMatches && !fetchingNextPage)
            }
            isError={careerMatchesErrored}
            onCareerClick={onCareerClick}
          />
        </CardBody>
        <CardFooter className="career-matches-footer">
          <div className="actions-container">
            {(hasNextPage || fetchingCareerMatches) && (
              <Button
                icon={["fa-regular", "arrow-down"]}
                loader={fetchingCareerMatches}
                onClick={onNextPage}
                disabled={fetchingCareerMatches}
                aria-live="assertive"
                aria-busy={fetchingCareerMatches}
                aria-label={
                  fetchingCareerMatches
                    ? "loading careers"
                    : "load more careers"
                }
                {...buttonTracking("career-matches", "load-more-careers")}
              >
                Load More Careers
              </Button>
            )}
            <Button
              icon={["fa-regular", "arrow-rotate-left"]}
              onClick={() => setOpenConfirmationModal(true)}
              {...buttonTracking("career-matches", "start-over")}
            >
              Start Over
            </Button>
          </div>
        </CardFooter>
      </Card>
      <Loader show={isStartingOver} text={"removing matches..."} />
      <CareerMatchesStartOverConfirmationModal
        open={openConfirmationModal}
        onConfirm={onConfirmStartOver}
        onCancel={closeConfirmationModal}
        onClose={closeConfirmationModal}
        careerTitle={careerPlan.career?.title}
      />
      {!fetchingCareerMatches && (
        <A11yAlertAnnouncer timeoutMs={500}>
          {pageSize} new careers loaded
        </A11yAlertAnnouncer>
      )}
    </>
  );
};

CareerMatches.displayName = "Career Matches Card";

CareerMatches.propTypes = {
  onCareerSelected: PropTypes.func,
  onClose: PropTypes.func,
  onStartOver: PropTypes.func,
};
