/* eslint react/no-unknown-property: 0 */

import { useAnimations, useGLTF } from "@react-three/drei";
import { forwardRef, useRef, useState } from "react";
import { mergeRefs } from "react-merge-refs";
import { Box3, Vector3 } from "three";

import { BirdModel } from "./bird.model";

import { useAnimationSequence } from "@pages/journey-map/hooks/use-animation-sequence";
import { useFollowPath } from "@pages/journey-map/hooks/use-follow-path";
import { randFloat } from "three/src/math/MathUtils";

const animationSequence = [
  {
    name: "ANIM_fly-1",
    clipDuration: 0.5,
    loopDurationRange: [1000, 6000],
    crossFadeDuration: 0.25,
  },
  { name: "ANIM_float-1", loopDurationRange: [1500, 3000] },
];

// Dynamic x- and z-coords based on distance from camera
const bounds = new Box3(
  new Vector3(Number.NaN, 50, Number.NaN),
  new Vector3(Number.NaN, 150, Number.NaN),
);

export const RandomBird = forwardRef((props, ref) => {
  const groupRef = useRef();

  // Approximately between 2.5 and 10 seconds to fly across the screen
  // Calculated as 1 / <value>
  const [speed, setSpeed] = useState(randFloat(0.1, 0.4));

  const { animations } = useGLTF("/models/bird.glb");
  const { actions } = useAnimations(animations, groupRef);

  const { play, stop } = useAnimationSequence(actions, animationSequence);
  const { position } = useFollowPath({
    appearanceWindow: [10_000, 20_000],
    bounds,
    model: groupRef.current,
    speed,
    onComplete: () => {
      groupRef.current.visible = false;
      stop?.();
      setSpeed(randFloat(0.1, 0.4));
    },
    onStart: () => {
      play?.();
      groupRef.current.visible = true;
    },
  });

  return (
    <BirdModel
      ref={mergeRefs([groupRef, ref])}
      position={position}
      {...props}
    />
  );
});

RandomBird.displayName = "Random Bird Model";
