import clsx from "clsx";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { ErrorToast } from "@features/ui/toast";
import { Button, Input } from "@transfr-inc/dashboard-components/forms";

import { isSsoEnabled } from "@lib/app-features.helpers";
import { buttonTracking } from "@lib/tracking.helpers";

import { AppRoutes } from "src/routes";

import "./login-form.scss";

export const LoginForm = ({
  className,
  error,
  loading,
  onResetLogin,
  onSignIn,
}) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [openError, setOpenError] = useState(false);

  useEffect(() => {
    setOpenError(!!error);
  }, [error]);

  return (
    <form
      className={clsx("login-form-content", className)}
      onSubmit={(e) => onSignIn(e, email, password)}
    >
      <ErrorToast
        open={openError}
        onClose={() => setOpenError()}
        message={error?.detail}
        closable
      />
      <div className="login-fields-container">
        <Input
          id="login-email"
          label="Email or Username"
          value={email}
          disabled={loading}
          onChange={(e) => setEmail(e.target.value)}
          aria-label="email or username"
        />
        <Input
          id="login-password"
          label="Password"
          type="password"
          value={password}
          disabled={loading}
          onChange={(e) => setPassword(e.target.value)}
          aria-label="password"
        />
      </div>
      <Button
        disabled={loading}
        loader={loading}
        type="submit"
        {...buttonTracking("login", "sign-in")}
      >
        Sign In
      </Button>
      <div className="login-form-forgot-password">
        <Link to={AppRoutes.forgotPassword}>Forgot Password?</Link>
      </div>
      {isSsoEnabled() && (
        <div className="login-form-sso">
          <Link onClick={() => onResetLogin()}>Sign in with SSO</Link>
        </div>
      )}
    </form>
  );
};

LoginForm.propTypes = {
  className: PropTypes.string,
  error: PropTypes.shape({ detail: PropTypes.string }),
  loading: PropTypes.bool,
  onResetLogin: PropTypes.func,
  onSignIn: PropTypes.func,
};
