/* eslint react/no-unknown-property: 0 */

import { Canvas, invalidate } from "@react-three/fiber";
import { motion } from "framer-motion";
import PropTypes from "prop-types";
import {
  forwardRef,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";

import {
  JourneyMapBackground,
  JourneyMapFooter,
  JourneyMapHeader,
} from "../../components";
import { JourneyMapCanvas } from "./journey-map.canvas";

import {
  Bloom,
  EffectComposer,
  N8AO,
  SMAA,
  Vignette,
} from "@react-three/postprocessing";

import { useGpu } from "@hooks/use-gpu";
import { A11yAnnouncer } from "@react-three/a11y";
import { PerformanceMonitor } from "@react-three/drei";
import { BlendFunction } from "postprocessing";

export const JourneyMapView = forwardRef(
  (
    {
      pageHeight,
      currentMap,
      triggerMapAnimation,
      moveToMap,
      moveToStep,
      onMapAnimationComplete,
      unlockedSteps,
      isPlanShared,
    },
    ref,
  ) => {
    const headerRef = useRef();
    const footerRef = useRef();

    const [maxDpr, setMaxDpr] = useState(2);
    const [mapDimensions, setMapDimensions] = useState({
      top: 0,
      bottom: 0,
      height: 0,
    });

    const { frameDurationMs } = useGpu();

    useEffect(() => {
      // This effect controls the render loop for the entire map
      let previousTime = 0;
      let requestId;

      const render = (timestamp) => {
        // Target a frame rate that is appropriate for the user's GPU
        if (timestamp - previousTime > frameDurationMs) {
          invalidate();
          previousTime = timestamp;
        }
        requestId = requestAnimationFrame(render);
      };
      render();

      return () => {
        cancelAnimationFrame(requestId);
      };
    }, []);

    useLayoutEffect(() => {
      const handleResize = () => {
        const bottom = footerRef.current?.offsetHeight;
        const top = headerRef.current?.offsetHeight;
        const height = pageHeight - top - bottom;

        setMapDimensions({
          top,
          bottom,
          height,
        });
      };
      handleResize();

      window.addEventListener("resize", handleResize);
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }, [currentMap, headerRef.current, pageHeight, footerRef.current]);

    return (
      <motion.div
        className="journey-map-view mode-3d"
        ref={ref}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        layout
      >
        <JourneyMapBackground currentMap={currentMap} isMap3DEnabled />
        <JourneyMapFooter ref={footerRef} inverted />
        <div className="journey-map">
          <Canvas
            frameloop="demand"
            shadows
            gl={{
              antialias: false,
              depth: false,
              stencil: false,
            }}
            camera={{ near: 10, far: 1500, position: [0, 460, 440] }}
            dpr={[1, maxDpr]}
          >
            <fog attach="fog" args={["white", 850, 1200]} />
            <color attach="background" args={["white"]} />
            {/*lighting*/}
            <directionalLight
              castShadow
              intensity={3}
              position={[800, 600, 500]}
              shadow-mapSize={1024}
              shadow-bias={-0.0001}
            >
              <orthographicCamera
                attach="shadow-camera"
                args={[-400, 400, 400, -400, 1, 100_000]}
              />
            </directionalLight>
            <ambientLight intensity={1} />

            <PerformanceMonitor
              iterations={5}
              onChange={({ factor }) => {
                setMaxDpr(Math.max(1, 0.5 + 1.5 * factor));
              }}
            >
              <JourneyMapCanvas
                dimensions={mapDimensions}
                isPlanShared={isPlanShared}
                triggerMapAnimation={triggerMapAnimation}
                onMapAnimationComplete={onMapAnimationComplete}
                onMoveToMap={moveToMap}
                onMoveToStep={moveToStep}
                showMap={currentMap}
                unlockedSteps={unlockedSteps}
              />
            </PerformanceMonitor>

            <EffectComposer multisampling={0} enableNormalPass={false}>
              <Bloom
                blendFunction={BlendFunction.SCREEN}
                intensity={1.25}
                luminanceThreshold={1}
                levels={4}
                luminanceSmoothing={0.4}
                mipmapBlur
              />
              <Vignette eskil={false} offset={0.1} darkness={0.6} />
              <N8AO aoRadius={5} distanceFalloff={0.5} intensity={5} halfRes />
              <SMAA />
            </EffectComposer>
          </Canvas>
          <A11yAnnouncer />
        </div>
        <JourneyMapHeader ref={headerRef} inverted />
      </motion.div>
    );
  },
);
JourneyMapView.displayName = "Journey Map View";
JourneyMapView.propTypes = {
  currentMap: PropTypes.number,
  isPlanShared: PropTypes.bool,
  moveToMap: PropTypes.func,
  moveToStep: PropTypes.func,
  onMapAnimationComplete: PropTypes.func,
  pageHeight: PropTypes.number,
  triggerMapAnimation: PropTypes.number,
  unlockedSteps: PropTypes.array,
};
