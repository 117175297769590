import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import PropTypes from "prop-types";

import { ListItem } from "@features/ui";
import { Tooltip } from "@features/ui/tooltip";

import "./shortcut.list-item.scss";

const getItemIcon = (completed, unlocked) => {
  if (completed) {
    return ["check", "complete"];
  }
  if (!unlocked) {
    return ["lock", "locked"];
  }
  return ["circle", "incomplete"];
};

export const ShortcutListItem = ({ completed, onClick, step, unlocked }) => {
  const [itemIcon, iconTitle] = getItemIcon(completed, unlocked);

  return (
    <ListItem
      disabled={!unlocked}
      className={clsx("shortcut-list-item", !unlocked && "disabled")}
      onClick={onClick}
    >
      <div className="step-state-icon">
        <span className={clsx(completed && "completed")}>
          {itemIcon && (
            <Tooltip content={step.lockMessage} disabled={unlocked}>
              <FontAwesomeIcon
                icon={["fasr", itemIcon]}
                title={iconTitle}
                className={clsx(iconTitle === "incomplete" && "a11y-only")}
              />
            </Tooltip>
          )}
        </span>
      </div>
      <div className="step-summary">
        <h3>{`${step.title} ${step?.required ? "*" : ""}`.trim()}</h3>
        <p>{step.description}</p>
      </div>
    </ListItem>
  );
};

ShortcutListItem.displayName = "Shortcut List Item";

ShortcutListItem.propTypes = {
  completed: PropTypes.bool,
  onClick: PropTypes.func,
  step: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    lockMessage: PropTypes.string,
    code: PropTypes.string,
    required: PropTypes.bool,
  }),
  unlocked: PropTypes.bool,
};
