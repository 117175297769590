import clsx from "clsx";
import { AnimatePresence } from "framer-motion";
import PropTypes from "prop-types";
import { Children, useState } from "react";

import { Tab } from "./tab";
import { TabHeader } from "./tab.header";

import "./tabs.scss";

const isTabComponent = (child) => {
  return child?.type === Tab;
};

export const Tabs = ({ defaultTabIndex, onTabChange, className, children }) => {
  const tabs = Children.toArray(children).filter((c) => isTabComponent(c));

  const [activeIndex, setActiveIndex] = useState(defaultTabIndex ?? 0);

  const onTabChangeHandler = (index) => {
    onTabChange?.(index);
    setActiveIndex(index);
  };

  return (
    <div className={clsx("tabs-v2", className)} role="tabs">
      <div className="tabs-v2-headers" role="tablist">
        {tabs.map((t, index) => (
          <TabHeader
            tabIndex={index}
            onSelectTab={onTabChangeHandler}
            isActive={index === activeIndex}
            key={`tab-header.${t.props.id ?? index}`}
            aria-controls={t.props.id}
          >
            {t.props.title}
          </TabHeader>
        ))}
      </div>
      <AnimatePresence mode="wait" initial={false}>
        {tabs[activeIndex]}
      </AnimatePresence>
    </div>
  );
};

Tabs.displayName = "Tabs";

Tabs.propTypes = {
  defaultTabIndex: PropTypes.number,
  onTabChange: PropTypes.func,
  className: PropTypes.string,
  children: PropTypes.node,
};
