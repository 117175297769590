import PropTypes from "prop-types";

import { Button } from "@transfr-inc/dashboard-components/forms";

import { Card, CardBody, CardFooter, CardHeader, Modal } from "@features/ui";

import { buttonTracking } from "@lib/tracking.helpers";

import "./start-over-confirmation.modal.scss";

export const InterestInventoryStartOverConfirmationModal = ({
  open,
  onClose,
  onConfirm,
  onCancel,
}) => {
  const toolbar = (
    <Button
      icon={["fa-regular", "xmark"]}
      onClick={onClose}
      aria-label="close"
      {...buttonTracking(
        "interest-inventory-start-over-confirmation-modal",
        "close",
      )}
    />
  );

  return (
    <Modal
      key="interest-inventory-start-over"
      modalClassName="interest-inventory-start-over-confirmation-modal"
      open={open}
      role="alertdialog"
    >
      <Card>
        <CardHeader title={<h1>Start Over?</h1>} toolbar={toolbar} />
        <CardBody>
          Are you sure you would like to erase all of your answers?
        </CardBody>
        <CardFooter>
          <Button
            destructive
            onClick={onConfirm}
            {...buttonTracking(
              "interest-inventory-start-over-confirmation-modal",
              "yes",
            )}
          >
            Yes, start over
          </Button>
          <Button
            onClick={onCancel}
            {...buttonTracking(
              "interest-inventory-start-over-confirmation-modal",
              "no",
            )}
          >
            No, cancel
          </Button>
        </CardFooter>
      </Card>
    </Modal>
  );
};

InterestInventoryStartOverConfirmationModal.displayName =
  "Interest Inventory Start Over Confirmation Modal";

InterestInventoryStartOverConfirmationModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
};
