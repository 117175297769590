import { useFrame, useThree } from "@react-three/fiber";
import { useEffect, useMemo, useRef, useState } from "react";
import { Box3, CatmullRomCurve3, Matrix4, Vector3 } from "three";
import { useThrottledCallback } from "use-debounce";

import { useGpu } from "@hooks/use-gpu";
import { clamp } from "@lib/app.helpers";
import { arrayToVector } from "../normalize";

export const dashAnimations = {
  MoveHip: "ACT_hip_quad_trans",
  Relaxed: "ANIM_idle-2_relaxed",
  RelaxedTwo: "ANIM_idle-2_relaxed",
  RunFourLegs: "ANIM_4leg_walk",
  WalkTwoLegs: "ANIM_walk_2leg",
  RunTwoLegs: "ANIM_run_2Leg",
  Jump: "ANIM_Jump",
  StartWalking: "ANIM_2leg_to_4leg_transition",
  StopWalking: "ANIM_4leg_to_2leg_transition",
  Talking: "ANIM_talk_to_camera",
  TalkingLoop: "ANIM_talk_to_camera_loop",
};

const turnSpeed = 3;

let frameDelta = 0;
const pathing = {
  delta: 0,
  originDistance: 0,
  currentDistance: 0,
  destinationDistance: 0,
  lookAtOrigin: undefined,
  lookAtTarget: undefined,
  lookAtInterp: 0,
};

const clampDistance = clamp(0, 1);

const isDestinationReached = () =>
  Math.abs(pathing.currentDistance - pathing.originDistance) >=
  Math.abs(pathing.destinationDistance - pathing.originDistance);

export const useDashTheHedgehog = ({
  ref,
  initialPosition: [x, y, z] = [0, 0, 0],
  pathPoints = [],
  speed = 1,
  obstacles = [],
  setDash,
}) => {
  const onDestinationReachedRef = useRef();
  const [position, setPosition] = useState(new Vector3(x, y, z));
  const [animation, setAnimation] = useState({
    name: dashAnimations.Relaxed,
  });
  const [willMove, setWillMove] = useState();
  const { camera } = useThree();
  const { frameDurationMs } = useGpu();

  const dashDirection = useRef(1);
  const obstaclesRef = useMemo(
    () => obstacles.map((o) => ({ passed: false, obstacle: o })),
    [...obstacles],
  );

  // Useful for development process to display a Box3Helper reference
  const box3Ref = useRef(new Box3());

  // Define the path that Dash can move through
  const curve = useMemo(() => {
    const vectors = pathPoints?.map((point) => arrayToVector(point));
    pathing.currentDistance =
      vectors.findIndex((v) => v.equals(position)) / (vectors.length - 1);
    return new CatmullRomCurve3(vectors);
  }, [pathPoints]);

  const setDestination = ({ distance, onDestinationReached, options }) => {
    const { animation } = {
      animation: true,
      ...options,
    };
    onDestinationReachedRef.current = onDestinationReached;

    resetObstaclesStatus();

    if (
      animation &&
      // Don't move if Dash is already at the destination
      pathing.currentDistance !== distance
    ) {
      pathing.originDistance = pathing.currentDistance;
      pathing.delta = 0;
      pathing.destinationDistance = distance;

      dashDirection.current = distance > pathing.currentDistance ? 1 : -1;
      pathing.lookAtOrigin = ref.current.clone();
      pathing.lookAtTarget = ref.current.clone();
      pathing.lookAtTarget.lookAt(
        curve.getPointAt(
          clampDistance(pathing.currentDistance + dashDirection.current * 0.1),
        ),
      );

      setAnimation({
        name: dashAnimations.StartWalking,
        options: { once: true },
      });

      return true;
    } else {
      pathing.originDistance =
        pathing.currentDistance =
        pathing.destinationDistance =
          distance;
    }
  };

  const walkNextPathPoint = useThrottledCallback(() => {
    if (willMove) {
      const point = curve.getPointAt(clampDistance(pathing.currentDistance));
      setPosition(point);

      if (isDestinationReached()) {
        setWillMove();
        setAnimation({
          name: dashAnimations.StopWalking,
          options: { once: true },
        });
        // Make sure we're at the desired point.
        pathing.currentDistance = pathing.destinationDistance;
        setPosition(curve.getPointAt(pathing.destinationDistance));
      } else {
        // pathing.delta += (speed / (1 - pathing.originDistance)) * frameDelta;
        pathing.currentDistance += dashDirection.current * speed * frameDelta;
        frameDelta = 0;
        pathing.currentDistance = clampDistance(pathing.currentDistance);
        //  MathUtils.lerp(
        //   pathing.originDistance,
        //   pathing.destinationDistance,
        //   pathing.delta,
        // );
      }

      return point;
    }
  }, frameDurationMs);

  const onAnimationFinished = () => {
    switch (animation.name) {
      case dashAnimations.StartWalking: {
        setWillMove(true);
        setAnimation({
          name: dashAnimations.RunFourLegs,
          options: { duration: 0.5 },
        });
        break;
      }

      case dashAnimations.StopWalking: {
        setAnimation({ name: dashAnimations.Relaxed });
        break;
      }

      case dashAnimations.Jump: {
        if (isDestinationReached()) {
          setAnimation({ name: dashAnimations.Relaxed });
        } else {
          setWillMove(true);
          setAnimation({
            name: dashAnimations.RunFourLegs,
            options: { duration: 0.5 },
          });
        }
        break;
      }

      default: {
        setAnimation({
          name:
            Math.random() < 0.5
              ? dashAnimations.Relaxed
              : dashAnimations.RelaxedTwo,
        });
      }
    }
  };

  const resetObstaclesStatus = () => {
    for (const element of obstaclesRef) {
      element.passed = false;
    }
  };

  const getObstacleIndex = () => {
    box3Ref.current.setFromObject(ref.current, true);
    return obstaclesRef.findIndex(
      (element) =>
        !element.passed &&
        element.obstacle &&
        box3Ref.current.intersectsBox(element.obstacle.userData),
    );
  };

  const jumpObstacle = (obstacleIndex) => {
    // Stop and start jump animation
    setWillMove();
    setAnimation({
      name: dashAnimations.Jump,
      options: { once: true, duration: 0.8 },
    });
    // Jump forward effect
    setTimeout(() => {
      setWillMove(true);
    }, 300);
    // Landing effect
    setTimeout(() => {
      setWillMove();
    }, 600);

    // Set obstacle as passed perform jump action once per obstacle
    obstaclesRef[obstacleIndex].passed = true;
  };

  useFrame((_, delta) => {
    if (willMove) {
      // Accumulate deltas since walkNextPathPoint is throttled.
      frameDelta += delta;
      walkNextPathPoint();
    } else if (isDestinationReached()) {
      onDestinationReachedRef.current?.();
      onDestinationReachedRef.current = undefined;
    }

    if (pathing.lookAtTarget && pathing.lookAtInterp < 1) {
      pathing.lookAtInterp += delta * turnSpeed;
      ref.current.quaternion.slerpQuaternions(
        pathing.lookAtOrigin.quaternion,
        pathing.lookAtTarget.quaternion,
        Math.min(pathing.lookAtInterp, 1),
      );
    }
  }, 1);

  if (ref.current) {
    if (isDestinationReached()) {
      pathing.lookAtOrigin = ref.current.clone();
      pathing.lookAtTarget = ref.current.clone();
      pathing.lookAtTarget.lookAt(camera.position.clone().setY(20));
      pathing.lookAtInterp = 0;
    } else if (willMove) {
      // Get point slightly in front of where Dash currently is
      const lookAt = curve.getPointAt(
        clampDistance(pathing.currentDistance + dashDirection.current * 0.05),
      );

      // Using the Object3D lookAt has issues when parents are scaled
      // differently, so we'll just do our own...
      const rotationMatrix = new Matrix4();
      rotationMatrix.lookAt(lookAt, ref.current.position, ref.current.up);
      ref.current.quaternion.setFromRotationMatrix(rotationMatrix);
    }
  }

  useEffect(() => {
    const obstacleIndex = getObstacleIndex();
    if (obstacleIndex > -1) {
      jumpObstacle(obstacleIndex);
    }
  }, [position]);

  const dash = {
    animation,
    distance: pathing.currentDistance,
    onAnimationFinished,
    position,
    setDestination,
    walkNextPathPoint,
    willMove,
    box3: box3Ref,
  };

  setDash?.(dash);

  return dash;
};
