import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import { LayoutGroup, motion } from "framer-motion";
import PropTypes from "prop-types";
import { Children, createContext, useEffect, useMemo, useState } from "react";

import { useBreakpoints } from "@hooks/use-breakpoints";
import { buttonTracking } from "@lib/tracking.helpers";
import { LogoNavbar } from "./logo.navbar";

import "./navbar.scss";

export const NavbarContext = createContext();

export const Navbar = ({ className, children }) => {
  const { isDesktop, isTablet, isMobile } = useBreakpoints();
  const [isExpanded, setIsExpanded] = useState(false);

  const navbarVariants = useMemo(
    () => ({
      expanded: {
        width: "23.25rem",
        padding: "3.125rem",
      },
      collapsed: {
        width: isMobile ? "17.5rem" : "5rem",
        padding: isMobile ? "0.9375rem 1.25rem" : "1.875rem 0.625rem",
      },
    }),
    [isMobile],
  );

  const items = useMemo(() => {
    const nodes = { top: [], bottom: [] };
    const childrenArray = Children.toArray(children);

    if (isMobile) {
      nodes.top = childrenArray;
      return nodes;
    }

    for (const c of childrenArray) {
      if (c.props?.position === "bottom") {
        nodes.bottom.push(c);
      } else {
        nodes.top.push(c);
      }
    }

    return nodes;
  }, [children, isMobile]);

  useEffect(() => {
    if (!isDesktop) {
      setIsExpanded(false);
    }
  }, [isDesktop]);

  return (
    <NavbarContext.Provider value={{ isExpanded }}>
      <LayoutGroup>
        <motion.nav
          layout
          initial={false}
          animate={isExpanded ? "expanded" : "collapsed"}
          variants={navbarVariants}
          className={clsx("tx-navbar", isExpanded && "expanded", className)}
          transition={{
            when: "beforeChildren",
            type: "tween",
            duration: 0.3,
          }}
        >
          {isDesktop && (
            <motion.button
              layout
              transition={{ type: "spring" }}
              className="expander-button"
              onClick={() => setIsExpanded(!isExpanded)}
              whileHover={{ scale: 0.85 }}
              whileFocus={{ scale: 0.85 }}
              aria-label="navigation"
              aria-expanded={isExpanded}
              {...buttonTracking("navbar", "expand")}
            >
              <FontAwesomeIcon
                icon={["fasr", "arrow-right"]}
                rotation={isExpanded ? 180 : 0}
              />
            </motion.button>
          )}
          <LogoNavbar show={isTablet} />
          <motion.div className="top items-container">{items.top}</motion.div>
          {!isMobile && (
            <div className="bottom items-container">{items.bottom}</div>
          )}
        </motion.nav>
      </LayoutGroup>
    </NavbarContext.Provider>
  );
};

Navbar.display = "Navbar Item";

Navbar.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};
