import clsx from "clsx";
import { motion } from "framer-motion";
import PropTypes from "prop-types";
import { useId } from "react";

export const Tab = ({ className, children }) => {
  const tabId = useId();

  return (
    <motion.div
      className={clsx("tab-container-v2", className)}
      id={tabId}
      layout
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      initial={{ opacity: 0 }}
      transition={{ duration: 0.2 }}
      role="tabpanel"
    >
      {children}
    </motion.div>
  );
};

Tab.displayName = "Tab";

Tab.propTypes = {
  title: PropTypes.node,
  className: PropTypes.string,
  children: PropTypes.node,
};
