import PropTypes from "prop-types";
import { useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { buttonTracking } from "@lib/tracking.helpers";

import { ToggleButton } from "@transfr-inc/dashboard-components/forms";

import "./journey-view.toggle.scss";

export const JourneyViewToggle = ({ onToggle, value }) => {
  const [buttonTrackingSelection, setButtonTrackingSelection] =
    useState("map-view");

  const onChange = (value) => {
    setButtonTrackingSelection(value ? "shortcuts-view" : "map-view");
    onToggle?.();
  };

  return (
    <ToggleButton
      className="journey-view-toggle"
      onChange={onChange}
      affirmText={<FontAwesomeIcon icon={["fasr", "location-dot"]} />}
      negateText={<FontAwesomeIcon icon={["fasr", "list-check"]} />}
      aria-label="Shortcut list view"
      // value = journey map view state
      // but for toggle button state, the value is reversed
      // when value = false then the shortcuts list is on
      // when value = true then the shortcuts list is off
      value={!value}
      {...buttonTracking("explore", buttonTrackingSelection)}
    />
  );
};

JourneyViewToggle.displayName = "Journey View Toggle Button";

JourneyViewToggle.propTypes = {
  onToggle: PropTypes.func,
  value: PropTypes.bool,
};
