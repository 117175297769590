import clsx from "clsx";
import PropTypes from "prop-types";

import "./subheading.scss";

export const SubHeading = ({ className, children, ...props }) => {
  return (
    <h3 className={clsx("subheading", className)} {...props}>
      {children}
    </h3>
  );
};

SubHeading.displayName = "Subheading";

SubHeading.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};
