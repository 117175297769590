import { RouterProvider, createBrowserRouter } from "react-router-dom";

import { AuthProvider } from "@context/auth.context";
import { JourneyProvider } from "@context/journey.context";
import { AppQueryProvider } from "@context/query.provider";
import { A11yRouterAnnouncer } from "@features/a11y";
import { ProtectedRoute } from "@features/ui/protected-route";
import {
  FavoritesPage,
  ForgotPasswordPage,
  JourneyMapPage,
  LoginPage,
  ProfilePage,
  ResetPasswordPage,
  TermsAndConditionsPage,
} from "@pages/index";

import { App } from "./app";
import { AppRoutes } from "./routes";

import { ScreenBreakpointsProvider } from "@context/screen-breakpoints.context";
import { MobileLandscapeErrorModal } from "@features/a11y/mobile-landscape.error.modal";

export const Router = () => {
  const JourneyMapRoute = {
    element: <JourneyMapPage />,
    path: AppRoutes.journeyMap.pathname,
  };

  const ProfileRoute = {
    element: <ProfilePage />,
    path: AppRoutes.profile.pathname,
  };

  const FavoritesRoute = {
    element: <FavoritesPage />,
    path: AppRoutes.favorites.pathname,
  };

  const LoginRoute = {
    element: <LoginPage />,
    path: AppRoutes.login.pathname,
  };

  const ForgotPasswordRoute = {
    element: <ForgotPasswordPage />,
    path: AppRoutes.forgotPassword.pathname,
  };

  const ResetPasswordRoute = {
    element: <ResetPasswordPage />,
    path: AppRoutes.resetPassword.pathname,
  };

  const TermsRoute = {
    element: (
      <ProtectedRoute>
        <TermsAndConditionsPage />
      </ProtectedRoute>
    ),
    path: AppRoutes.terms.pathname,
  };

  const AppRoute = {
    element: (
      <ProtectedRoute>
        <JourneyProvider>
          <App />
        </JourneyProvider>
      </ProtectedRoute>
    ),
    path: AppRoutes.journeyMap.pathname,
    children: [JourneyMapRoute, ProfileRoute, FavoritesRoute],
  };

  const AllRoutes = {
    element: (
      <AuthProvider>
        <A11yRouterAnnouncer />
        <MobileLandscapeErrorModal />
      </AuthProvider>
    ),
    path: AppRoutes.journeyMap.pathname,
    children: [
      AppRoute,
      LoginRoute,
      TermsRoute,
      ForgotPasswordRoute,
      ResetPasswordRoute,
    ],
  };

  const router = createBrowserRouter([AllRoutes]);

  return (
    <ScreenBreakpointsProvider>
      <AppQueryProvider>
        <RouterProvider router={router} />
      </AppQueryProvider>
    </ScreenBreakpointsProvider>
  );
};
