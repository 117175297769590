import { LayoutGroup, motion } from "framer-motion";
import PropTypes from "prop-types";
import { useState } from "react";

import { Toast, ToastType } from "@features/ui";
import { useUpdateLinkTracking } from "@hooks/use-update-link-tracking";
import { Button } from "@transfr-inc/dashboard-components/forms";
import { LabeledToggle } from "./labeled-toggle";

import "./form.scss";

const AnimatedLabeledToggle = motion.create(LabeledToggle);
const AnimatedButton = motion.create(Button);

export const Form = ({ onSubmit, error }) => {
  useUpdateLinkTracking();

  const [isAgreementAccepted, setIsAgreementAccepted] = useState(false);

  const tacLabel = (
    <span>
      {"Transfr’s "}
      <a
        className="terms-and-conditions-link"
        href="https://transfrinc.com/terms/"
        target="_blank"
        rel="noopener noreferrer"
      >
        Terms of Service
      </a>
      {
        " govern your access to and use of Trek. By accessing or using Trek, you agree to these Terms and our "
      }
      <a
        className="terms-and-conditions-link"
        href="https://transfrinc.com/privacy-policy/"
        target="_blank"
        rel="noopener noreferrer"
      >
        Privacy Policy
      </a>
      .
    </span>
  );

  return (
    <div className="terms-and-conditions-form">
      <h1>Terms & Conditions</h1>
      <LayoutGroup>
        {error && (
          <Toast
            open
            type={ToastType.error}
            message="Something went wrong. Please try again."
          />
        )}
        <AnimatedLabeledToggle
          layout
          className="terms-and-conditions-toggle"
          label={tacLabel}
          value={isAgreementAccepted}
          onChange={() => setIsAgreementAccepted((v) => !v)}
        />
        <AnimatedButton
          layout
          className="sign-up-button"
          disabled={!isAgreementAccepted}
          onClick={() =>
            onSubmit({
              isAgreementAccepted,
            })
          }
        >
          Sign Up
        </AnimatedButton>
      </LayoutGroup>
    </div>
  );
};

Form.propTypes = {
  error: PropTypes.object,
  onSubmit: PropTypes.func,
};
