import clsx from "clsx";
import PropTypes from "prop-types";
import { Children, forwardRef } from "react";

import { Breadcrumb } from "../breadcrumb";

const getTitleNode = (children) => {
  const child = Children.toArray(children)[0];
  if (typeof child === "string" || child instanceof String) {
    return <h1>{children}</h1>;
  }

  return children;
};

export const CardHeader = forwardRef(
  (
    { breadcrumb, onBreadcrumbClick, title, toolbar, className, children },
    ref,
  ) => {
    const childrenTitle = getTitleNode(children);

    return (
      <div className={clsx("card-header", className)} ref={ref}>
        <div className="card-header-title-toolbar">
          <div className="card-header-title">
            {breadcrumb && (
              <div>
                <Breadcrumb onClick={onBreadcrumbClick}>
                  {breadcrumb}
                </Breadcrumb>
              </div>
            )}
            {title && <div className="title">{title}</div>}
          </div>
          <div className="card-header-toolbar">{toolbar}</div>
        </div>
        <div className="card-header-children">{childrenTitle}</div>
      </div>
    );
  },
);

CardHeader.displayName = "Card Header";

CardHeader.propTypes = {
  breadcrumb: PropTypes.string,
  onBreadcrumbClick: PropTypes.func,
  title: PropTypes.node,
  toolbar: PropTypes.node,
  children: PropTypes.node,
  className: PropTypes.string,
};
