import { library } from "@fortawesome/fontawesome-svg-core";

import {
  faArrowRight,
  faArrowUpFromBracket,
  faArrowUpRightFromSquare,
  faCheck,
  faCircle,
  faCircleCheck,
  faCircleUser,
  faDownload,
  faEye,
  faEyeSlash,
  faListCheck,
  faLocationDot,
  faLock,
  faSignsPost,
  faStars,
  faThumbsDown,
  faThumbsUp,
} from "@fortawesome/sharp-regular-svg-icons";

export const initSharpIcons = () => {
  library.add(
    faArrowRight,
    faArrowUpFromBracket,
    faArrowUpRightFromSquare,
    faCheck,
    faCircle,
    faCircleCheck,
    faCircleUser,
    faDownload,
    faEye,
    faEyeSlash,
    faListCheck,
    faLocationDot,
    faLock,
    faSignsPost,
    faStars,
    faThumbsDown,
    faThumbsUp,
  );
};
