import { useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

import { Button } from "@transfr-inc/dashboard-components/forms";

import { Case, CopyrightFooter, Loader, Switch } from "@features/ui";
import { Toast, ToastType } from "@features/ui/toast";

import { HeaderImage } from "./components/header-image";
import { LoginForm } from "./components/login-form";

import { useAuth } from "@hooks/use-auth";
import { isSsoEnabled } from "@lib/app-features.helpers";

import headerImageUrl from "@images/trek-logo.large.png?url";

import "./index.scss";

const cleverIdpUrl = import.meta.env.CLEVER_IDP_URL;
// const ssoLoginUrl = import.meta.env.SSO_LOGIN_URL;

const resetPasswordMessage = "Your password has been updated successfully.";
const expiredSessionMessage = (
  <div>
    Your session has expired.
    <br />
    Please try logging in again.
  </div>
);

export const LoginPage = () => {
  const [loginMethod, setLoginMethod] = useState(isSsoEnabled() ? "" : "email");
  const [ssoWaiting, setSsoWaiting] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();

  const [queryParams] = useSearchParams();
  const { login, loading, error } = useAuth();

  const [toastConfig, setToastConfig] = useState();

  useEffect(() => {
    if (queryParams.has("it")) {
      login({ impersonationToken: queryParams.get("it") });
      return;
    }
    if (isSsoEnabled() && queryParams.has("sso")) {
      login({ ssoToken: queryParams.get("sso") });
      return;
    }

    if (location.state?.passwordReset) {
      setToastConfig({
        type: ToastType.success,
        message: resetPasswordMessage,
      });
    } else if (location.state?.userLoginExpired) {
      setToastConfig({
        type: ToastType.error,
        message: expiredSessionMessage,
      });
    }

    const timeoutId = setTimeout(() => {
      setToastConfig();
      navigate("/login", { replace: true });
    }, 5000);

    return () => clearTimeout(timeoutId);
  }, []);

  const handleLogin = async (e, email, password) => {
    e.preventDefault();

    await login({ email, password });
  };

  return (
    <div className="login-page">
      <HeaderImage imageUrl={headerImageUrl} />
      <Toast
        open={!!toastConfig}
        message={toastConfig?.message}
        type={toastConfig?.type}
      />
      <Switch option={loginMethod}>
        <Case value="">
          {loading || ssoWaiting ? (
            <Loader show text="Logging in..." />
          ) : (
            <div className="form-options">
              <Button
                className="login-button login-email"
                onClick={() => setLoginMethod("email")}
              >
                <img src="/favicon.svg" alt="Transfr Logo" aria-hidden />
                <span>Sign in with Email</span>
              </Button>
              <div className="login-separator">
                <div />
                <div>OR</div>
                <div />
              </div>
              <Button
                className="login-button login-clever"
                onClick={() => {
                  setSsoWaiting(true);
                  window.location.href = cleverIdpUrl;
                }}
              >
                <img
                  src="/images/sso/clever.svg"
                  alt="Clever Logo"
                  aria-hidden
                />
                <span>Sign in with Clever</span>
              </Button>
              {/* <Button className="login-button login-classlink">
                <img
                  src="/images/sso/classlink.svg"
                  alt="Classlink Logo"
                  aria-hidden
                />
                <span>Sign in with Classlink</span>
              </Button>
              <Button className="login-button login-nydoe">
                <img src="/images/sso/nydoe.png" alt="NYDOE Logo" aria-hidden />
                <span>Sign in with NYDOE</span>
              </Button> */}
            </div>
          )}
        </Case>
        <Case value="email">
          <LoginForm
            loading={loading}
            error={error}
            onSignIn={handleLogin}
            onResetLogin={() => setLoginMethod("")}
          />
        </Case>
      </Switch>
      <CopyrightFooter />
    </div>
  );
};
