/* eslint react/no-unknown-property: 0 */

import { useThree } from "@react-three/fiber";
import { AnimatePresence, useMotionValue } from "framer-motion";
import { motion } from "framer-motion-3d";
import PropTypes from "prop-types";
import { useLayoutEffect } from "react";
import { MathUtils } from "three";

import { Case, Switch } from "@features/ui";

import { PROTO_ASPECT } from "@pages/journey-map/normalize";

import { MapFour } from "./map-four";
import { MapOne } from "./map-one";
import { MapThree } from "./map-three";
import { MapTwo } from "./map-two";

const FOV = 55;

export const JourneyMapCanvas = ({
  dimensions,
  isPlanShared,
  triggerMapAnimation,
  onMapAnimationComplete,
  onMoveToMap,
  onMoveToStep,
  scrollAnimationDuration,
  showMap,
  unlockedSteps,
}) => {
  const { viewport, camera } = useThree();

  const { top, bottom } = dimensions;
  const { height: vHeight, width: vWidth } = viewport;

  const mapZPosition = useMotionValue(0);

  const visibleHeight = vHeight + top + bottom;

  // Manipulating camera settings as a way to handle responsiveness
  useLayoutEffect(() => {
    camera.position?.set(0, 460, 440);
    camera.lookAt(0, 0, 0);

    // Set new camera settings
    // Taken from:
    // https://discourse.threejs.org/t/keeping-an-object-scaled-based-on-the-bounds-of-the-canvas-really-battling-to-explain-this-one/17574/10
    camera.aspect = vWidth / vHeight;
    if (camera.aspect > PROTO_ASPECT) {
      camera.fov = FOV;
    } else {
      // window too narrow
      const cameraHeight = Math.tan(MathUtils.degToRad(FOV / 2));
      const ratio = camera.aspect / PROTO_ASPECT;
      const newCameraHeight = cameraHeight / ratio;
      camera.fov = MathUtils.radToDeg(Math.atan(newCameraHeight)) * 2;
    }

    // Apply updated settings
    camera.updateProjectionMatrix();
  }, [viewport]);

  return (
    <group>
      {showMap && (
        <motion.group
          position-z={mapZPosition}
          animate={{
            z: -(showMap - 1) * visibleHeight,
          }}
          transition={{ duration: scrollAnimationDuration }}
        >
          <AnimatePresence mode="popLayout">
            <Switch option={showMap}>
              <Case value={4}>
                {/* Add a bit more to the z-position since map four has an empty
                    island at the bottom that we don't really need to show. */}
                <group position-z={3 * visibleHeight + 50}>
                  <MapFour
                    isPlanShared={isPlanShared}
                    onMoveToStep={onMoveToStep}
                    unlockedSteps={unlockedSteps}
                    triggerMapAnimation={triggerMapAnimation}
                  />
                </group>
              </Case>
              <Case value={3}>
                <group position-z={2 * visibleHeight}>
                  <MapThree onMoveToStep={onMoveToStep} />
                </group>
              </Case>
              <Case value={2}>
                <group position-z={visibleHeight}>
                  <MapTwo
                    onMoveToStep={onMoveToStep}
                    onMapAnimationComplete={onMapAnimationComplete}
                  />
                </group>
              </Case>
              <Case value={1}>
                <group>
                  <MapOne
                    onMoveToStep={onMoveToStep}
                    onMoveToMap={onMoveToMap}
                    triggerMapAnimation={triggerMapAnimation}
                    onMapAnimationComplete={onMapAnimationComplete}
                  />
                </group>
              </Case>
            </Switch>
          </AnimatePresence>
        </motion.group>
      )}
    </group>
  );
};

JourneyMapCanvas.defaultProps = {
  scrollAnimationDuration: 0.5,
};

JourneyMapCanvas.propTypes = {
  dimensions: PropTypes.shape({
    top: PropTypes.number,
    bottom: PropTypes.number,
    height: PropTypes.number,
  }),
  isPlanShared: PropTypes.bool,
  triggerMapAnimation: PropTypes.number,
  onMapAnimationComplete: PropTypes.func,
  onMoveToMap: PropTypes.func,
  onMoveToStep: PropTypes.func,
  onViewportChange: PropTypes.func,
  scrollAnimationDuration: PropTypes.number,
  showMap: PropTypes.number,
  unlockedSteps: PropTypes.arrayOf(PropTypes.bool),
};
