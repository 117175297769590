import { useDetectGPU } from "@react-three/drei";
import { DEFAULT_FPS, MAX_FPS, TARGET_FPS } from "src/constants";

export const useGpu = () => {
  const detectedGpu = useDetectGPU();

  let targetFps = DEFAULT_FPS;
  if (detectedGpu.fps > DEFAULT_FPS) {
    targetFps = detectedGpu.fps;
  } else if (detectedGpu.tier > 3) {
    targetFps = MAX_FPS;
  } else if (TARGET_FPS[detectedGpu.tier]) {
    targetFps = TARGET_FPS[detectedGpu.tier];
  }

  return {
    targetFps,
    // Duration for a single frame, in milliseconds
    frameDurationMs: 1000 / targetFps,
  };
};
