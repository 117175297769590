import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import PropTypes from "prop-types";

import "./question.list-item.scss";

export const QuestionListItem = ({ value }) => {
  const answerIcon = value.answer ? "thumbs-up" : "thumbs-down";

  return (
    <div className="interest-inventory-question-item">
      <span className="question">{value.question}</span>
      <FontAwesomeIcon
        className={clsx("answer-icon", value.answer && "affirmative")}
        icon={["fasr", answerIcon]}
        title={value.answer ? "yes" : "no"}
      />
    </div>
  );
};

QuestionListItem.displayName = "Interest Inventory Question List Item";

QuestionListItem.propTypes = {
  value: PropTypes.shape({
    question: PropTypes.string,
    answer: PropTypes.bool,
  }),
};
