import clsx from "clsx";
import { motion } from "framer-motion";
import PropTypes from "prop-types";
import { useEffect, useRef, useState } from "react";

import "./progress-bar.scss";

export const ProgressBar = ({ min, max, value, className }) => {
  const barRef = useRef();
  const initialProgressXPosition = { x: "-100%" };
  const [animate, setAnimate] = useState(initialProgressXPosition);

  useEffect(() => {
    if (barRef.current) {
      try {
        const { width } = barRef.current.getBoundingClientRect();
        const progressXPosition = width - (width / (max - min)) * (value - min);
        setAnimate({ x: -progressXPosition });
      } catch (error) {
        console.error(
          `Error while calculating progress position. For more details:`,
          error,
        );
      }
    }
  }, [value]);

  return (
    <div
      className={clsx("tx-progress-bar", className)}
      role="progressbar"
      aria-valuemax={max}
      aria-valuemin={min}
      aria-valuenow={value}
    >
      <div ref={barRef} className="bar">
        <motion.div
          className="progress-fill"
          initial={initialProgressXPosition}
          animate={animate}
          transition={{ duration: 1 }}
        ></motion.div>
      </div>
    </div>
  );
};

ProgressBar.displayName = "Progress Bar";

ProgressBar.propTypes = {
  value: PropTypes.number.isRequired,
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  className: PropTypes.string,
};
