/* eslint react/no-unknown-property: 0 */

import PropTypes from "prop-types";
import { useMemo, useRef, useState } from "react";

import {
  CabinMedium,
  CabinSmall,
  Campfire,
  DashTheHedgehog,
  Winter,
} from "@pages/journey-map/3d-models";
import {
  JourneyButton3D,
  JourneyMapZPositionedGroup,
  JourneyPin,
  Overlay,
  Snowing,
} from "@pages/journey-map/components";
import { NonBlockingA11y } from "@pages/journey-map/components/non-blocking.a11y";

import { useSkills } from "@features/skills/api/use-skills";
import { useAuth } from "@hooks/use-auth";
import { useJourneySteps } from "@pages/journey-map/api/use-journey-steps";
import { useUserJourney } from "@pages/journey-map/api/use-user-journey";
import { useUserJourneyStatus } from "@pages/journey-map/api/use-user-journey-status";
import {
  useDashIdleDetection,
  useDashTheHedgehog,
} from "@pages/journey-map/hooks";
import { useHoverAnimation } from "@pages/journey-map/hooks/use-hover-animation";
import { useIntro } from "@pages/journey-map/hooks/use-intro";

import { noPropagationHandler } from "@lib/app.helpers";
import { usePerformanceMonitor } from "@react-three/drei";

const introInitialPosition = [80, 0, -200];
const highSchoolPathPoint = [-120, 0, -320];
const skillsPathPoint = [-90, 0, -50];
const postHighSchoolPathPoint = [-60, 10, 220];

const introPathPoints = [
  introInitialPosition,
  [-80, 0, -250],
  highSchoolPathPoint,
];

const mainPathPoints = [
  highSchoolPathPoint,
  [-150, -5, -300],
  [-200, -5, -250],
  [-220, 0, -200],
  [-190, 0, -100],
  [-170, 0, -80],
  [-120, 0, -50],
  skillsPathPoint,
  [-20, 0, -30],
  [-10, 5, 90],
  [-25, 10, 140],
  [-40, 10, 180],
  postHighSchoolPathPoint,
];

const mapStepsCodes = {
  highSchool: "highSch",
  skills: "skills",
  postHighSchool: "postHighSch",
};

export const MapThree = ({ onMoveToStep }) => {
  const dashRef = useRef();

  const [performanceFactor, setPerformanceFactor] = useState(1);

  usePerformanceMonitor({
    onChange: ({ factor }) => {
      setPerformanceFactor(factor);
    },
  });

  const { user } = useAuth();
  const { data: userJourney } = useUserJourney();
  const { data: skills } = useSkills(userJourney.currentJourneyCareer?.id);

  const { data: journeySteps } = useJourneySteps();
  const { data: userJourneyStatus } = useUserJourneyStatus();

  const postHighSchoolEnabled = !!user.msa;

  const hoverAnimationValues = { values: { scale: 1.1 } };
  const skillsHoverAnimation = useHoverAnimation(hoverAnimationValues);
  const highSchoolHoverAnimation = useHoverAnimation(hoverAnimationValues);
  const postHighSchoolHoverAnimation = useHoverAnimation({
    ...hoverAnimationValues,
    enabled: postHighSchoolEnabled,
  });

  const visitedSteps = useMemo(
    () =>
      new Set(
        Object.entries(userJourneyStatus ?? {})
          .map(([key, value]) => value && key)
          .filter(Boolean),
      ),
    [userJourneyStatus],
  );

  const { closeIntro, dashProps, speech, viewedIntro } = useIntro({
    introKey: "map3",
    initialPosition: highSchoolPathPoint,
    introInitialPosition,
    introPathPoints,
    mainPathPoints,
    text: "Let's learn what it will take to get over this mountain!",
  });
  const dash = useDashTheHedgehog({
    ref: dashRef,
    ...dashProps,
  });
  const { idleSpeech, stopIdleDetection } = useDashIdleDetection({
    onIdle: () => {
      if (!visitedSteps.has(mapStepsCodes.highSchool)) {
        highSchoolHoverAnimation.getAttention();
      } else if (!visitedSteps.has(mapStepsCodes.skills)) {
        skillsHoverAnimation.getAttention();
      } else if (
        postHighSchoolEnabled &&
        !visitedSteps.has(mapStepsCodes.postHighSchool)
      ) {
        postHighSchoolHoverAnimation.getAttention();
      }
    },
    speech: {
      animationDuration: 5000,
      text: "Sharpen your quills, I mean skills, and explore what's next.",
      textDuration: 0,
      pinConfig: {
        size: [140, 60],
        fontSize: 10,
      },
    },
    enabled: !!viewedIntro,
  });

  const openSheet = (distance, step) => {
    stopIdleDetection();

    dash.setDestination({
      distance,
      onDestinationReached: () => onMoveToStep(step),
    });
  };

  const onHighSchoolClick = noPropagationHandler(() => {
    openSheet(0, journeySteps.stepsByCode.HIGH_SCH);
    visitedSteps.add(mapStepsCodes.highSchool);
  });

  const onSkillsClick = noPropagationHandler(() => {
    openSheet(
      mainPathPoints.indexOf(skillsPathPoint) / (mainPathPoints.length - 1),
      journeySteps.stepsByCode.SKILLS,
    );
    visitedSteps.add(mapStepsCodes.skills);
  });

  const onPostHighSchoolClick = noPropagationHandler(() => {
    postHighSchoolEnabled &&
      openSheet(1, journeySteps.stepsByCode.POST_HIGH_SCH);
    visitedSteps.add(mapStepsCodes.postHighSchool);
  });

  return (
    <JourneyMapZPositionedGroup>
      <Overlay enabled={!viewedIntro} onClick={closeIntro} />
      <DashTheHedgehog
        ref={dashRef}
        position={dash.position}
        scale={70}
        animation={dash.animation}
        outfit={["winter", "fannyPack"]}
        onActionFinished={dash.onAnimationFinished}
        speech={speech ?? idleSpeech}
      />
      <group
        position={[50, 0, -60]}
        scale={1}
        rotation={[Math.PI * -0.005, Math.PI * 0.65, 0]}
      >
        <Winter scale={100} />
        <Snowing numParticles={1000 * performanceFactor} />
        <NonBlockingA11y
          role="button"
          description="high school"
          actionCall={onHighSchoolClick}
          active={viewedIntro}
        >
          <JourneyButton3D
            position={[390, 0, 30]}
            onClick={onHighSchoolClick}
            hoverAnimation={highSchoolHoverAnimation}
          >
            <JourneyPin
              size={[70, 25]}
              padding={5}
              position={[-60, 80, -30]}
              rotation={[Math.PI * 0.12, Math.PI * 1.35, Math.PI * 0.1]}
              text="High School"
              fontSize={10}
              color="#8a6240"
              fontColor="white"
            />
            <CabinMedium
              scale={160}
              position={[0, 0, 0]}
              rotation={[0, 1, 0]}
            />
          </JourneyButton3D>
        </NonBlockingA11y>

        <NonBlockingA11y
          role="button"
          description="see what skills you need for your selected pathway"
          actionCall={onSkillsClick}
          active={viewedIntro}
        >
          <JourneyButton3D
            position={[125, 10, -110]}
            hoverAnimation={skillsHoverAnimation}
            onClick={onSkillsClick}
          >
            <JourneyPin
              size={[50, 20]}
              padding={5}
              position={[0, 50, 0]}
              rotation={[Math.PI * 0.15, Math.PI * 1.4, Math.PI * 0.12]}
              text={`Skills ${skills?.length ?? "..."}`}
              fontSize={10}
              color="#8a6240"
              fontColor="white"
            />
            <Campfire scale={120} animated />
          </JourneyButton3D>
        </NonBlockingA11y>

        <NonBlockingA11y
          role="button"
          description="extended learning"
          actionCall={onPostHighSchoolClick}
          active={viewedIntro}
        >
          <JourneyButton3D
            position={[-235, 0, -150]}
            onClick={onPostHighSchoolClick}
            hoverAnimation={postHighSchoolHoverAnimation}
          >
            {postHighSchoolEnabled && (
              <JourneyPin
                size={[70, 40]}
                padding={5}
                text="Extended Learning"
                position={[-10, 100, -10]}
                rotation={[Math.PI * 0.15, Math.PI * 1.25, Math.PI * 0.12]}
                color="#8a6240"
                fontColor="white"
                fontSize={10}
              />
            )}
            <CabinSmall scale={150} rotation={[0, Math.PI * 0.15, 0]} />
          </JourneyButton3D>
        </NonBlockingA11y>
      </group>
    </JourneyMapZPositionedGroup>
  );
};

MapThree.propTypes = {
  onMoveToStep: PropTypes.func,
};
