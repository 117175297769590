export const AppRoutes = {
  favorites: {
    name: "Favorites",
    pathname: "/favorites",
    className: "favorites",
  },
  forgotPassword: {
    name: "Forgot Password",
    pathname: "/forgot-password",
  },
  journeyMap: {
    name: "Journey Map",
    pathname: "/",
    className: "map",
  },
  login: {
    name: "Login",
    pathname: "/login",
  },
  profile: {
    name: "Profile",
    pathname: "/profile",
    className: "profile",
  },
  resetPassword: {
    name: "Reset Password",
    pathname: "/reset-password",
  },
  terms: {
    name: "Terms and Conditions",
    pathname: "/terms",
  },
};
