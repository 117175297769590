import { Outlet, useLocation } from "react-router-dom";

import { AppRoutes } from "../../routes";

export const A11yRouterAnnouncer = () => {
  const location = useLocation();
  const locationPath = location.pathname;
  const appRoute = Object.values(AppRoutes).find(
    (ar) => ar.pathname === locationPath,
  );
  const locationName = appRoute.name;

  return (
    <>
      <span className="a11y-only" aria-live="polite" aria-atomic="true">
        Navigated to {locationName} page
      </span>
      <Outlet />
    </>
  );
};
