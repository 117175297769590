import PropTypes from "prop-types";
import { useState } from "react";

import { motion } from "framer-motion";

import { buttonTracking } from "@lib/tracking.helpers";
import { careerExpertQuestionType } from "src/types";

import "./question.card.scss";

const altText = [
  "Person smiling at the camera while in the setting of their workplace.",
  "Person working on the job, preparing for work, unloading equipment for work.",
  "People driving where they work, walking into a building where they work, arriving in the morning, or leaving at sunset/night.",
  "The landscape or type of building/setting where this career normally takes place.",
  "A diverse group of people in this profession working on location.",
  "Customers experiencing the work done by someone in this profession.",
  "Someone in this profession using a computer for their job.",
  "Someone in this profession on location.",
  "Someone in this profession working with a group of their peers to achieve a goal.",
];

const TRANSITION = { ease: "easeOut", duration: 0.3 };

export const QuestionCard = ({ transfrId, careerExpertQuestion }) => {
  const { orderNum, question, answer } = careerExpertQuestion;
  const image = `/images/careers/${transfrId}/questions/${orderNum}.png`;

  const [animateProps, setAnimateProps] = useState({});
  const { opacity, y } = animateProps;

  return (
    <button
      className="question-card"
      onClick={() => {
        if (opacity === 0) {
          setAnimateProps({ opacity: 1 });
        } else {
          setAnimateProps({
            opacity: 0,
            y: "-10.625rem",
          });
        }
      }}
      {...buttonTracking("career-expert", `question-${orderNum}`)}
    >
      <motion.img
        className="question-card-image"
        animate={{ opacity }}
        src={image}
        alt={altText[orderNum]}
        transition={TRANSITION}
        aria-hidden
      />
      <motion.div
        className="question-answer-container"
        animate={{ y }}
        transition={TRANSITION}
      >
        <motion.div
          className="question-card-question"
          animate={{ opacity }}
          transition={TRANSITION}
          aria-hidden={opacity === 0}
        >
          {question}
        </motion.div>
        <motion.div
          className="question-card-answer"
          animate={{ opacity: 1 - opacity }}
          transition={TRANSITION}
          aria-hidden={opacity !== 0}
        >
          {answer}
        </motion.div>
      </motion.div>
    </button>
  );
};

QuestionCard.propTypes = {
  transfrId: PropTypes.string,
  careerExpertQuestion: careerExpertQuestionType,
};
