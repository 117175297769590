import PropTypes from "prop-types";

import { Card, CardBody, CardHeader, ErrorToast } from "@features/ui";
import { Button } from "@transfr-inc/dashboard-components/forms";
import { CareerExpertQuestionsSection, CareerExpertSection } from "./views";

import { aOrAn } from "@lib/text.helpers";
import { buttonTracking } from "@lib/tracking.helpers";
import { minimalCareerType } from "src/types";

import { useCareerExpert } from "./api/use-career-expert";

import "./index.scss";

export const CareerExpert = ({ career, onClose, onBreadcrumbClick }) => {
  const { data: careerExpert, isError } = useCareerExpert(career.transfrId);

  const toolbar = (
    <Button
      onClick={onClose}
      icon={["fa-regular", "xmark"]}
      title="close"
      {...buttonTracking("career-expert", "close")}
    />
  );

  return (
    <Card className="career-expert-card">
      <CardHeader
        breadcrumb="Career Details"
        onBreadcrumbClick={onBreadcrumbClick}
        toolbar={toolbar}
      >
        <h1 tabIndex={-1}>Ask {aOrAn(career.title)}</h1>
      </CardHeader>
      <CardBody className="career-expert-card-body">
        {isError ? (
          <ErrorToast
            open
            message="No questions available for now, please try again later."
          />
        ) : (
          <>
            <CareerExpertSection career={career} name={careerExpert?.name} />
            <CareerExpertQuestionsSection
              transfrId={career.transfrId}
              questions={careerExpert?.questions}
            />
          </>
        )}
      </CardBody>
    </Card>
  );
};

CareerExpert.displayName = "Career Expert Card";

CareerExpert.propTypes = {
  career: minimalCareerType,
  onClose: PropTypes.func,
  onBreadcrumbClick: PropTypes.func,
};
