import { PointMaterial, Points } from "@react-three/drei";
import { useFrame } from "@react-three/fiber";
import PropTypes from "prop-types";

import { useSnowing } from "../hooks";

export const Snowing = ({ numParticles }) => {
  const { snowflakeTexture, snowflakesPositions, moveSnowflakes } = useSnowing({
    numParticles,
  });

  useFrame((_, delta) => {
    if (snowflakesPositions.length > 0) {
      // Let it snow!
      moveSnowflakes(delta);
    }
  });

  return (
    <Points positions={snowflakesPositions}>
      <PointMaterial
        size={8}
        map={snowflakeTexture}
        transparent
        sizeAttenuation={true}
        vertexColors={false}
        depthWrite={false}
      />
    </Points>
  );
};

Snowing.propTypes = {
  numParticles: PropTypes.number,
};
