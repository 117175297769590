/* eslint react/no-unknown-property: 0 */

import PropTypes from "prop-types";
import { useEffect, useRef } from "react";

import {
  DashTheHedgehog,
  Desert,
  LogFannyPack,
  RandomBird,
  RandomTumbleWeed,
} from "@pages/journey-map/3d-models";
import {
  JourneyButton3D,
  JourneyMapZPositionedGroup,
  JourneyPin,
  Overlay,
} from "@pages/journey-map/components";
import { NonBlockingA11y } from "@pages/journey-map/components/non-blocking.a11y";

import { useJourneySteps } from "@pages/journey-map/api/use-journey-steps";
import {
  useDashIdleDetection,
  useDashTheHedgehog,
  useHoverAnimation,
  useJourney,
} from "@pages/journey-map/hooks";
import { useIntro } from "@pages/journey-map/hooks/use-intro";

import { STEP_TO_MAP } from "@context/journey.context";
import { noPropagationHandler } from "@lib/app.helpers";

const dashInitialPosition = [-40, 0, -300];
const getReadyPathPosition = [40, 0, 130];

const mainPathPoints = [
  dashInitialPosition,
  [-40, 0, -250],
  [-50, 0, -225],
  [-90, 0, -160],
  [-120, 0, -110],
  [-130, 0, -80],
  [-135, 0, -50],
  [-130, 0, -20],
  [-120, 0, 0],
  [-90, 0, 20],
  [-20, 0, 60],
  [30, 0, 90],
  [40, 0, 110],
  getReadyPathPosition,
];

export const MapOne = ({
  onMapAnimationComplete,
  onMoveToStep,
  triggerMapAnimation,
}) => {
  const dashRef = useRef();

  const getReadyHoverAnimation = useHoverAnimation();

  const { data: journeySteps } = useJourneySteps();
  const { nextMapUnlocked } = useJourney();

  const { closeIntro, dashProps, speech, viewedIntro } = useIntro({
    introKey: "map1",
    initialPosition: nextMapUnlocked
      ? getReadyPathPosition
      : dashInitialPosition,
    mainPathPoints,
    text: `Let's get ready for your Trek! Hehe, get it... "Trek."`,
  });

  const dash = useDashTheHedgehog({
    ref: dashRef,
    ...dashProps,
  });

  const { idleSpeech, stopIdleDetection, startIdleDetection } =
    useDashIdleDetection({
      onIdle: () => !nextMapUnlocked && getReadyHoverAnimation.getAttention(),
      speech: {
        animationDuration: 5000,
        text: nextMapUnlocked
          ? "Let's roll and find your pathway."
          : "Don't hedge your bets, let's get ready!",
        pinConfig: {
          size: [140, 40],
          fontSize: 10,
        },
      },
      enabled: !!viewedIntro,
    });

  const openInterestInventorySheet = () => {
    stopIdleDetection();
    dash.setDestination({
      distance: 1,
      onDestinationReached: () => {
        onMoveToStep(journeySteps.stepsByCode.INT_INV);
        onMapAnimationComplete?.();
      },
    });
  };

  const onGetReadyClick = noPropagationHandler(openInterestInventorySheet);

  useEffect(() => {
    if (viewedIntro && triggerMapAnimation === STEP_TO_MAP.INT_INV) {
      if (dash.distance === 1) {
        startIdleDetection();
        onMapAnimationComplete?.();
      } else {
        stopIdleDetection();
        dash.setDestination({
          distance: 1,
          onDestinationReached: startIdleDetection,
        });
      }
    }
  }, [viewedIntro, triggerMapAnimation]);

  return (
    <JourneyMapZPositionedGroup>
      <Overlay enabled={!viewedIntro} onClick={closeIntro} />

      <RandomBird scale={75} />
      <RandomTumbleWeed scale={25} />
      <DashTheHedgehog
        ref={dashRef}
        position={dash.position}
        scale={90}
        animation={dash.animation}
        onActionFinished={dash.onAnimationFinished}
        speech={speech ?? idleSpeech}
        outfit={[nextMapUnlocked ? "fannyPack" : "none"]}
      />

      <NonBlockingA11y
        role="button"
        description="get ready"
        actionCall={onGetReadyClick}
        active={viewedIntro}
      >
        <JourneyButton3D
          position={[-45, 0, 110]}
          hoverAnimation={getReadyHoverAnimation}
          onClick={onGetReadyClick}
        >
          <JourneyPin
            size={[50, 16]}
            position={[30, 60, 0]}
            rotation={[-0.4, Math.PI * 0.05, 0]}
            text={"Get Ready"}
            fontSize={8}
          />
          <LogFannyPack
            animated
            position={[30, 0, 20]}
            scale={60}
            rotation={[0, Math.PI * 0.7, 0]}
            showFannypack={!nextMapUnlocked}
          />
        </JourneyButton3D>
      </NonBlockingA11y>

      <Desert
        position={[40, 0, 50]}
        scale={100}
        rotation={[0, Math.PI * 0.7, 0]}
      />
    </JourneyMapZPositionedGroup>
  );
};

MapOne.propTypes = {
  onMapAnimationComplete: PropTypes.func,
  onMoveToStep: PropTypes.func,
  triggerMapAnimation: PropTypes.number,
  mapDimensions: PropTypes.object,
};
