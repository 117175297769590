import clsx from "clsx";
import PropTypes from "prop-types";
import { forwardRef } from "react";

import "./card.scss";

export const Card = forwardRef(({ className, children, ...props }, ref) => {
  return (
    <div ref={ref} className={clsx("card", className)} {...props}>
      {children}
    </div>
  );
});

Card.displayName = "Card";

Card.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};
