import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";

import { useOrientation } from "@transfr-inc/dashboard-components/hooks";

import { Card, CardBody, CardHeader, Modal } from "@features/ui";

import { useBreakpoints } from "@hooks/use-breakpoints";

import "./mobile-landscape.error.modal.scss";

export const MobileLandscapeErrorModal = () => {
  const [showLandscapeAlert, setShowLandscapeAlert] = useState(false);

  const { isMobile } = useBreakpoints();
  const orientation = useOrientation();

  useEffect(() => {
    setShowLandscapeAlert(isMobile && orientation.isLandscape());
  }, [orientation]);

  return (
    <Modal
      key="mobile-landscape-error"
      open={showLandscapeAlert}
      role="alertdialog"
      modalClassName="mobile-landscape-error-modal"
    >
      <Card>
        <CardHeader>
          <h1 tabIndex={0}>
            <FontAwesomeIcon icon={["far", "fa-triangle-exclamation"]} />
            Landscape orientation not supported!
          </h1>
        </CardHeader>
        <CardBody>
          Landscape orientation is not supported on smaller devices. Please
          either rotate your device to portrait orientation or view this app on
          a larger device.
        </CardBody>
      </Card>
    </Modal>
  );
};
