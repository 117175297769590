/* eslint react/no-unknown-property: 0 */

import PropTypes from "prop-types";
import { useEffect, useState } from "react";

export const JourneyMapZPositionedGroup = ({
  children,
  referenceSelector = ".journey-map-header",
}) => {
  const [position, setPosition] = useState([0, 0, 0]);

  useEffect(() => {
    const calculatePosition = () => {
      const referenceElement = document.querySelector(referenceSelector);
      // Get element' styles, they could be declared in a stylesheet or inline.
      const elementStyles =
        window.getComputedStyle(referenceElement) || referenceElement.style;
      const { paddingBottom } = elementStyles;
      // Get the actual number. Above it's returned with the px suffix.
      const paddinBottomValue = Number.parseInt(
        paddingBottom.replaceAll(/[A-z]/g, ""),
      );

      // Move the group through the Z-axis
      setPosition([0, 0, paddinBottomValue * -1]);
    };

    calculatePosition();
    window.addEventListener("resize", calculatePosition);

    return () => window.removeEventListener("resize", calculatePosition);
  }, []);

  return <group position={position}>{children}</group>;
};

JourneyMapZPositionedGroup.propTypes = {
  children: PropTypes.node,
  referenceSelector: PropTypes.string,
};
