import clsx from "clsx";
import PropTypes from "prop-types";
import { forwardRef } from "react";

export const CardBody = forwardRef(({ className, children, ...rest }, ref) => {
  return (
    <div ref={ref} className={clsx("card-body", className)} {...rest}>
      {children}
    </div>
  );
});

CardBody.displayName = "Card Body";

CardBody.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};
