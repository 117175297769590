import clsx from "clsx";
import PropTypes from "prop-types";
import { useMemo } from "react";

import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  ErrorToast,
  HiddenTag,
  Loader,
  SelectedTag,
} from "@features/ui";
import { Button } from "@transfr-inc/dashboard-components/forms";

import {
  AskAnExpertSection,
  CloserLookSection,
  EmploymentSection,
  OverviewSection,
  PathwaySection,
  SkillsSection,
} from "./views";

import { useUpdateUserJourney } from "@pages/journey-map/api/use-update-user-journey";
import { useUserJourney } from "@pages/journey-map/api/use-user-journey";

import { buttonTracking } from "@lib/tracking.helpers";
import { minimalCareerType } from "src/types";
import { useCareerDetails } from "./api/use-career-details";
import { useCareerPlan } from "./api/use-career-plan";
import { usePathwaySteps } from "./api/use-pathway-steps";

import {
  CareerRating,
  useSetUserCareerRating,
} from "@features/interest-inventory/api/use-set-user-career-rating";
import { useUserCareerRatings } from "@features/interest-inventory/api/use-user-career-ratings";
import { useUserFavoriteCareers } from "@pages/favorites/api/use-user-favorite-careers";
import { useAddUserFavoriteCareer } from "./api/use-add-user-favorite-career";
import { useRemoveUserFavoriteCareer } from "./api/use-remove-user-favorite-career";

import { useUserSimulations } from "./api/use-user-simulations";
import "./index.scss";

export const CareerDetails = ({
  breadcrumbText = "Career Matches",
  career,
  index,
  onExpertClick,
  onClose,
  onBreadcrumbClick,
  onCareerSelected,
}) => {
  const { id: careerId, transfrId } = career;

  const {
    data: { currentJourneyCareer },
    isFetching: userJourneyFetching,
  } = useUserJourney();
  const { data: favorites, isFetching: gettingFavorites } =
    useUserFavoriteCareers();

  const {
    data: careerDetails,
    isError,
    minimalData,
    isFetching: careerDetailsLoading,
  } = useCareerDetails(transfrId, index, career);

  const pathwaySteps = usePathwaySteps(transfrId);
  const { mutateAsync: updateJourney, isPending: updatingUserJourney } =
    useUpdateUserJourney();
  const { mutateAsync: addUserFavoriteCareer } = useAddUserFavoriteCareer({
    careerId,
    invalidateUserCareerMatches: true,
  });
  const { mutateAsync: removeUserFavoriteCareer } =
    useRemoveUserFavoriteCareer(careerId);

  const { data: careersRatings } = useUserCareerRatings();
  const {
    mutateAsync: setRating,
    isPending: ratingCareer,
    isError: ratingError,
  } = useSetUserCareerRating({ careerId, invalidateUserCareerMatches: true });

  const onViewPathwayClick = async () => {
    if (currentJourneyCareer?.id !== careerId) {
      await updateJourney({ careerId });
    }

    onCareerSelected?.(career);
  };

  const { data: careerPlan, isError: careerPlanError } = useCareerPlan();

  const { data: userSimulations } = useUserSimulations();

  const {
    cluster,
    description,
    title,
    skills,
    regional,
    national,
    simulations: careerSimulations,
  } = careerDetails ?? minimalData;

  const isSelected = careerPlan.submittedAt && careerPlan.careerId === careerId;

  const isFavorited =
    favorites?.some(({ career: { id } }) => id === careerId) ?? false;

  const hidden =
    careersRatings?.some(
      ({ careerId: id, rating }) =>
        id === careerId && rating === CareerRating.zero,
    ) ?? false;

  const onFavoriteClick = () => {
    if (isFavorited) {
      removeUserFavoriteCareer();
    } else {
      addUserFavoriteCareer();
    }
  };

  const onHideClick = () => {
    setRating(hidden ? CareerRating.one : CareerRating.zero);
  };

  const toolbar = (
    <>
      <Button
        className={clsx("favorite", isFavorited && "selected")}
        icon={[isFavorited ? "fa-solid" : "fa-regular", "star"]}
        onClick={onFavoriteClick}
        tooltip={isFavorited ? "Remove Favorite" : "Favorite"}
        title={
          isFavorited
            ? "remove from favorite careers"
            : "add to favorite careers"
        }
        {...buttonTracking("career-details", "favorite")}
      />
      <Button
        className={clsx("hide", hidden && "selected")}
        disabled={isFavorited || isSelected || gettingFavorites}
        icon={["fasr", hidden ? "eye" : "eye-slash"]}
        onClick={onHideClick}
        tooltip={hidden ? "Show" : "Hide"}
        title={hidden ? "show career" : "hide career"}
        {...buttonTracking("career-details", "hide")}
      />
      <Button
        onClick={onClose}
        icon={["fa-regular", "xmark"]}
        title="close"
        {...buttonTracking("career-details", "close")}
      />
    </>
  );

  const headerTags = useMemo(() => {
    if (isSelected || hidden) {
      return (
        <div className="header-tags">
          {isSelected && <SelectedTag />}
          {hidden && <HiddenTag />}
        </div>
      );
    }
  }, [careerPlan, hidden]);

  return (
    <>
      <Card className="career-details-card">
        <CardHeader
          breadcrumb={breadcrumbText}
          onBreadcrumbClick={onBreadcrumbClick}
          toolbar={toolbar}
        >
          <h1 tabIndex={-1}>{title}</h1>
          {headerTags}
        </CardHeader>
        <CardBody className="career-details-card-body">
          <ErrorToast
            open={isError || careerPlanError}
            message="Information might be incomplete due an error. Please try again later."
          />
          <ErrorToast
            open={ratingError}
            message="Something went wrong. Please try again."
          />
          <CloserLookSection
            careerSimulations={careerSimulations}
            userSimulations={userSimulations ?? []}
          />
          <AskAnExpertSection
            transfrId={transfrId}
            title={title}
            onClick={onExpertClick}
          />
          <OverviewSection
            cluster={cluster?.name}
            clusterDescription={cluster?.description}
            jobDescription={description}
            loading={careerDetailsLoading}
          />
          <EmploymentSection
            regionalData={regional}
            nationalData={national}
            loading={careerDetailsLoading}
          />
          <SkillsSection skills={skills} loading={careerDetailsLoading} />
          <PathwaySection pathwaySteps={pathwaySteps} />
        </CardBody>
        <CardFooter className="career-details-footer">
          <div className="actions-container">
            <Button
              primary
              onClick={onViewPathwayClick}
              {...buttonTracking("career-details", "view-full-pathway")}
            >
              View Full Pathway
            </Button>
            <p>
              FYI You can change this decision and start over as many times as
              you would like.
            </p>
          </div>
        </CardFooter>
      </Card>
      <Loader
        show={updatingUserJourney || userJourneyFetching}
        text="creating your pathway..."
      />
      <Loader
        show={ratingCareer}
        text={hidden ? "Unhiding career..." : "Hidding career..."}
      />
    </>
  );
};

CareerDetails.displayName = "Career Details Card";

CareerDetails.propTypes = {
  breadcrumbText: PropTypes.string,
  career: minimalCareerType,
  index: PropTypes.number,
  onExpertClick: PropTypes.func,
  onClose: PropTypes.func,
  onBreadcrumbClick: PropTypes.func,
  onCareerSelected: PropTypes.func,
};
