import { arrow, flip, shift } from "@floating-ui/core";
import { autoUpdate, offset, useFloating } from "@floating-ui/react-dom";
import clsx from "clsx";
import { AnimatePresence, motion } from "framer-motion";
import { VALUE_ENTER, VALUE_ESCAPE } from "keycode-js";
import PropTypes from "prop-types";
import { useEffect, useMemo, useState } from "react";

import "./tooltip.scss";

export const Tooltip = ({
  allowInteraction = true,
  animationHover,
  animationTap,
  children,
  className,
  clickDisabled,
  content,
  disabled,
  hoverDisabled,
  left = 0,
  maxWidth,
  offset: offsetProp = 10,
  open,
  placement: placementProp = "top",
  top = -2,
}) => {
  const [isOpen, setIsOpen] = useState(open ?? false);
  const [isForcedClose, setIsForcedClose] = useState(false);
  const [arrowRef, setArrowRef] = useState();

  useEffect(() => {
    setIsOpen(open);
  }, [open]);

  const { refs, floatingStyles, middlewareData, placement } = useFloating({
    placement: placementProp,
    middleware: [
      offset(offsetProp),
      flip(),
      shift(),
      arrow({
        element: arrowRef,
      }),
    ],
    whileElementsMounted: autoUpdate,
  });

  const { arrow: arrowPosition } = middlewareData;

  const initialTop = useMemo(
    () => (placement == "top" ? offsetProp : -1 * offsetProp),
    [placement],
  );

  const toggleTooltip = (value) => {
    setIsOpen(value);
  };

  const onClickHandler = (event) => {
    if (content && !clickDisabled) {
      event.stopPropagation();
      event.preventDefault();
      toggleTooltip(!isOpen);
      setIsForcedClose(isOpen);
    }
  };

  const onHoverHandler = (isHovered) => {
    if (content && !hoverDisabled) {
      // If the user has closed the tooltip by pressing Escape but they are
      // still hovered, don't show the tooltip until they un-hover and re-hover
      // the item.
      if (!isForcedClose) {
        toggleTooltip(isHovered);
      }
      if (!isHovered) {
        setIsForcedClose(false);
      }
    }
  };

  const onKeyUpHandler = (e) => {
    if (e.key === VALUE_ESCAPE) {
      setIsForcedClose(true);
      toggleTooltip(false);
    }
  };

  useEffect(() => {
    window.addEventListener("keyup", onKeyUpHandler);

    () => {
      window.removeEventListener("keyup", onKeyUpHandler);
    };
  }, []);

  if (disabled) {
    return children;
  }

  return (
    <div
      className={clsx("tx-tooltip", className)}
      onMouseEnter={() => onHoverHandler(true)}
      onMouseLeave={() => onHoverHandler(false)}
    >
      <motion.div
        // An issue with onTap event makes that we cannot stop the event propagation.
        // As a workaround we're making the tooltip keyboard-friendly
        // in the old school way
        // Source: https://github.com/motiondivision/motion/issues/363
        tabIndex={0}
        ref={refs.setReference}
        onClick={onClickHandler}
        onKeyPress={(e) => e.key === VALUE_ENTER && onClickHandler(e)}
        className="tooltip-reference"
        whileHover={animationHover}
        whileTap={animationTap}
      >
        {children}
      </motion.div>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            onClick={(e) => {
              // Make sure users can click into the tooltip to select text.
              e.stopPropagation();
            }}
            initial={{ opacity: 0, top: initialTop }}
            animate={{
              opacity: 1,
              top,
              transitionEnd: {
                pointerEvents: allowInteraction ? "auto" : "none",
              },
            }}
            exit={{ opacity: 0, top: initialTop }}
            ref={refs.setFloating}
            style={{ ...floatingStyles, maxWidth, left }}
            className="tooltip-element"
            role="tooltip"
          >
            {content}
            <div
              className="tooltip-element-arrow"
              ref={setArrowRef}
              style={{
                left: (arrowPosition?.x ?? 0) - left,
                top: placement === "top" ? arrowPosition?.y : 0,
              }}
            ></div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

Tooltip.displayName = "Tooltip";

Tooltip.propTypes = {
  allowInteraction: PropTypes.bool,
  animationHover: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  animationTap: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  children: PropTypes.node,
  className: PropTypes.string,
  clickDisabled: PropTypes.bool,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  disabled: PropTypes.bool,
  hoverDisabled: PropTypes.bool,
  left: PropTypes.number,
  maxWidth: PropTypes.number,
  offset: PropTypes.number,
  open: PropTypes.bool,
  placement: PropTypes.oneOf(["top", "bottom"]),
  top: PropTypes.number,
};
