import { motion } from "framer-motion";
import PropTypes from "prop-types";
import { useEffect, useMemo } from "react";

import { useThemeColor } from "@hooks/use-theme-color";

import colors from "@styles/colors.module.scss";

import "./journey.background.scss";

const gradientColors3D = {
  1: [colors.lightYellowBrownMap, colors.lightYellowBrown],
  2: [colors.lightBlueBackgroundMap, colors.veryLightGreen],
  3: [colors.white, colors.white],
  4: [colors.lightYellowBrownMap, colors.lightYellowBrown],
};

const gradientColors2D = {
  1: [colors.veryLightYellow, colors.lightYellowBrown],
  2: [colors.chimeGray, colors.veryLightGreen],
  3: [colors.white, colors.white],
  4: [colors.veryLightPaleGreen, colors.veryLightPaleGreen],
};

const gradientVariants = {
  stopColor1: ({ gradientColors, num }) => ({
    stopColor: gradientColors[num]?.[0],
  }),
  stopColor2: ({ gradientColors, num }) => ({
    stopColor: gradientColors[num]?.[1],
  }),
};

export const JourneyMapBackground = ({ currentMap, isMap3DEnabled }) => {
  const { updateColor } = useThemeColor();

  const gradientColors = useMemo(
    () => (isMap3DEnabled ? gradientColors3D : gradientColors2D),
    [isMap3DEnabled],
  );

  useEffect(() => {
    if (currentMap) {
      updateColor(gradientColors[currentMap][0]);
    }
  }, [currentMap]);

  return (
    <svg
      className="journey-map-background"
      viewBox="0 0 100 150"
      preserveAspectRatio="none"
      width="100%"
      height="100%"
      aria-hidden
    >
      <linearGradient id="gradient" gradientTransform="rotate(90)">
        <motion.stop
          custom={{ num: currentMap, gradientColors }}
          offset="12.73%"
          animate="stopColor1"
          variants={gradientVariants}
          transition={{
            duration: 0.25,
          }}
        />
        <motion.stop
          custom={{ num: currentMap, gradientColors }}
          offset="80.46%"
          animate="stopColor2"
          variants={gradientVariants}
          transition={{
            duration: 0.25,
          }}
        />
      </linearGradient>
      <rect x="0" y="0" width="100%" height="100%" fill="url(#gradient)" />
    </svg>
  );
};

JourneyMapBackground.propTypes = {
  currentMap: PropTypes.number,
  isMap3DEnabled: PropTypes.bool,
};
