import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AnimatePresence, motion } from "framer-motion";
import PropTypes from "prop-types";
import { useEffect, useLayoutEffect, useMemo, useState } from "react";

import { Button } from "@transfr-inc/dashboard-components/forms";

import { A11yAlertAnnouncer } from "@features/a11y";
import { Case, Switch } from "@features/ui";
import { useTimer } from "@hooks/use-timer";
import { OpacityTransitions } from "@lib/animation.helpers";
import { buttonTracking } from "@lib/tracking.helpers";

import "./sim-pass.button.scss";

const SHOW_EXPIRED_MS = 2000;

const ButtonStatus = {
  readyToGenerate: 0,
  active: 1,
  expired: 2,
};

export const SimPassButton = ({
  alternativeLoginCode,
  isGeneratingCode,
  onClick,
}) => {
  const [showExpired, setShowExpired] = useState(false);

  const timer = useTimer();

  const buttonStatus = useMemo(() => {
    if (alternativeLoginCode && !timer.isDone) {
      return ButtonStatus.active;
    } else if (alternativeLoginCode && timer.isDone && showExpired) {
      return ButtonStatus.expired;
    } else {
      return ButtonStatus.readyToGenerate;
    }
  }, [alternativeLoginCode, timer.isDone, showExpired]);

  const a11yAlternativeLoginCodeFormatted = useMemo(
    () =>
      alternativeLoginCode
        ? [...alternativeLoginCode.code.padStart(5, "0")].join(" ")
        : undefined,
    [alternativeLoginCode],
  );

  useEffect(() => {
    if (alternativeLoginCode) {
      setShowExpired(true);
      timer.setTimer(alternativeLoginCode.ttlSec);
    }
  }, [alternativeLoginCode]);

  useLayoutEffect(() => {
    if (buttonStatus === ButtonStatus.expired) {
      const timeoutId = setTimeout(
        () => setShowExpired(false),
        SHOW_EXPIRED_MS,
      );

      return () => clearTimeout(timeoutId);
    }
  }, [buttonStatus]);

  return (
    <>
      <div className="sim-pass-button">
        <AnimatePresence initial={false} mode="wait">
          <Switch option={buttonStatus}>
            <Case value={ButtonStatus.active}>
              <motion.div
                className="code-display"
                key="code-display"
                {...OpacityTransitions}
              >
                <span className="code" aria-label="sim pass code generated">
                  <span className="a11y-only">
                    {a11yAlternativeLoginCodeFormatted}
                  </span>
                  <span aria-hidden>
                    {a11yAlternativeLoginCodeFormatted?.replaceAll(" ", "")}
                  </span>
                </span>
                <div
                  className="time"
                  role="timer"
                  aria-label="minutes until sim pass expiration"
                >
                  <FontAwesomeIcon icon={["fa-solid", "fa-clock"]} />
                  {timer.timeFormatted}
                </div>
              </motion.div>
            </Case>
            <Case value={ButtonStatus.expired}>
              <motion.div
                className="expired"
                key="expired"
                aria-hidden
                {...OpacityTransitions}
              >
                <FontAwesomeIcon icon={["fa-solid", "fa-clock"]} />
                Pass Expired
              </motion.div>
            </Case>
            <Case value={ButtonStatus.readyToGenerate}>
              <motion.div key="get-sim-pass" {...OpacityTransitions}>
                <Button
                  icon={
                    isGeneratingCode ? undefined : ["fa-light", "fa-ticket"]
                  }
                  loader={isGeneratingCode}
                  onClick={onClick}
                  disabled={isGeneratingCode}
                  {...buttonTracking("career-details", "get-sim-pass")}
                >
                  Get Sim Pass
                </Button>
              </motion.div>
            </Case>
          </Switch>
        </AnimatePresence>
      </div>
      {/* 
				A11y announcers added after the original node to avoid repeated announcements,
				not sure why but here we only get the alert announcent once
			*/}
      <Switch option={buttonStatus}>
        <Case value={ButtonStatus.active}>
          <A11yAlertAnnouncer>
            sim pass {a11yAlternativeLoginCodeFormatted} generated,{" "}
            {timer.timeFormatted} minutes until expire
          </A11yAlertAnnouncer>
        </Case>
        <Case value={ButtonStatus.expired}>
          <A11yAlertAnnouncer>sim pass expired</A11yAlertAnnouncer>
        </Case>
      </Switch>
    </>
  );
};

SimPassButton.propTypes = {
  alternativeLoginCode: PropTypes.shape({
    code: PropTypes.string,
    ttlSec: PropTypes.number,
  }),
  isGeneratingCode: PropTypes.bool,
  onClick: PropTypes.func,
};
