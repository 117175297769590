import clsx from "clsx";
import { motion } from "framer-motion";
import PropTypes from "prop-types";

import { buttonTracking } from "@lib/tracking.helpers";

export const TabHeader = ({
  tabIndex,
  isActive,
  onSelectTab,
  children,
  ...props
}) => {
  return (
    <button
      onClick={() => onSelectTab(tabIndex)}
      className={clsx("tab-header-v2", isActive && "active")}
      role="tab"
      aria-selected={isActive}
      {...props}
      {...buttonTracking("tab", children)}
    >
      {children}
      {isActive && (
        <motion.div className="underline" layoutId="underline"></motion.div>
      )}
    </button>
  );
};

TabHeader.displayName = "Tab Header";

TabHeader.propTypes = {
  tabIndex: PropTypes.number,
  isActive: PropTypes.bool,
  onSelectTab: PropTypes.func,
  children: PropTypes.node,
};
