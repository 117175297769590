import PropTypes from "prop-types";
import { useEffect, useState } from "react";

export const A11yAlertAnnouncer = ({ timeoutMs = 10, children, ...props }) => {
  const [showA11yAlert, setShowA11yAlert] = useState(false);

  useEffect(() => {
    setShowA11yAlert(true);
    const timeoutId = setTimeout(() => setShowA11yAlert(false), timeoutMs);

    return () => clearTimeout(timeoutId);
  }, []);

  return (
    <span className="a11y-only" role="alert" {...props}>
      {showA11yAlert && <span>{children}</span>}
    </span>
  );
};

A11yAlertAnnouncer.propTypes = {
  timeoutMs: PropTypes.number,
  children: PropTypes.node,
};
