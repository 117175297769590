const AppFeaturesPrefix = "FEATURE__";
const AppFeaturesPrefixLength = AppFeaturesPrefix.length;
const EnvVars = import.meta.env;

const appFeaturesMap = new Map(
  Object.entries(EnvVars)
    .map(([key, value]) => {
      if (key.startsWith(AppFeaturesPrefix)) {
        const propKey = key.slice(AppFeaturesPrefixLength);
        const boolValue = value.toLowerCase() === "true";
        return [propKey, boolValue];
      }
    })
    .filter(Boolean),
);

const isEnabled = (featureKey) => appFeaturesMap.get(featureKey) ?? false;

export const isSsoEnabled = () => isEnabled("SSO");
