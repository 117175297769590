import { useMemo } from "react";
import { FloatType, MathUtils, Texture, Vector3 } from "three";

const SNOWFLAKE_DIAMETER = 32;
const MAX_PARTICLES = 1000;

const getRandomParticlePosition = (lowerLimit = -500, upperLimit = 500) => {
  return [
    MathUtils.randFloat(lowerLimit, upperLimit),
    MathUtils.randFloat(0, upperLimit),
    MathUtils.randFloat(lowerLimit, upperLimit),
  ];
};

const initParticlesPositions = (size) => {
  const points = new Float32Array(size * 3);

  for (let i = 0; i < size * 3; i += 3) {
    points.set(getRandomParticlePosition(), i);
  }

  return points;
};

// Using the canvas, draw a "snowflake"
const drawRadialGradation = (ctx, canvasRadius, canvasW, canvasH) => {
  ctx.save();
  const gradient = ctx.createRadialGradient(
    canvasRadius,
    canvasRadius,
    0,
    canvasRadius,
    canvasRadius,
    canvasRadius,
  );
  gradient.addColorStop(0, "rgba(255,255,255,1.0)");
  gradient.addColorStop(0.5, "rgba(255,255,255,0.5)");
  gradient.addColorStop(1, "rgba(255,255,255,0)");
  ctx.fillStyle = gradient;
  ctx.fillRect(0, 0, canvasW, canvasH);
  ctx.restore();
};

// Create the snowflake texture using a native HTML canvas
const getSnowflakeTexture = (snowflakeDiameter = SNOWFLAKE_DIAMETER) => {
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");
  const canvasRadius = snowflakeDiameter / 2;

  canvas.width = snowflakeDiameter;
  canvas.height = snowflakeDiameter;

  drawRadialGradation(ctx, canvasRadius, canvas.width, canvas.height);

  const texture = new Texture(canvas);
  texture.type = FloatType;
  texture.needsUpdate = true;

  return texture;
};

const velocities = [];
for (let i = 0; i < MAX_PARTICLES; i++) {
  const x = Math.floor(Math.random() * 6 - 3) * 5;
  const y = Math.floor(Math.random() * 10 + 5) * -5;
  const z = Math.floor(Math.random() * 6 - 3) * 5;
  const particle = new Vector3(x, y, z);
  velocities.push(particle);
}

export const useSnowing = ({ numParticles, snowflakeDiameter }) => {
  console.assert(
    numParticles <= MAX_PARTICLES,
    `numParticles cannot be greater than ${MAX_PARTICLES}`,
  );

  const snowflakesPositions = useMemo(
    () => initParticlesPositions(MAX_PARTICLES),
    [],
  );

  const snowflakeTexture = useMemo(
    () => getSnowflakeTexture(snowflakeDiameter),
    [snowflakeDiameter],
  );

  const moveSnowflakes = (factor) => {
    // Each snowflake position is composed by three array consecutive values
    let i = 0;
    for (; i < Math.min(numParticles * 3, MAX_PARTICLES * 3); i += 3) {
      // Get current snowflake position
      const x = snowflakesPositions[i];
      const y = snowflakesPositions[i + 1];
      const z = snowflakesPositions[i + 2];
      const vel = velocities[i / 3];

      // Calculate new snowflake position.
      const calculatedX = x + factor * vel.x;
      const calculatedY = y + factor * vel.y;
      const calculatedZ = z + factor * vel.z;

      // When snowflake has touched the floor then restart its position
      const newSnowflakePosition =
        calculatedY < 0
          ? getRandomParticlePosition()
          : [calculatedX, calculatedY, calculatedZ];
      snowflakesPositions.set(newSnowflakePosition, i);
    }
    // Move "extra" snowflakes off-screen
    snowflakesPositions.fill(-1000, i);
  };

  return { snowflakeTexture, snowflakesPositions, moveSnowflakes };
};
