import clsx from "clsx";
import { useEffect, useMemo } from "react";
import { Outlet, useLocation } from "react-router-dom";

import {
  Badge,
  ImpersonationBar,
  Navbar,
  NavbarItem,
  ProfileItemNavbar,
} from "@features/ui";
import { useAuth } from "@hooks/use-auth";
import { useDoSync } from "@hooks/use-sync-state";
import { useThemeColor } from "@hooks/use-theme-color";
import { useUserFavoriteCareers } from "@pages/favorites/api/use-user-favorite-careers";
import { useJourney } from "@pages/journey-map/hooks/use-journey";

import colors from "@styles/colors.module.scss";

import { AppRoutes } from "./routes";

import "./app.scss";

const themeColors = {
  profile: colors.lightBlue,
  favorites: colors.jasmine,
};

export const App = () => {
  const { user } = useAuth();
  const location = useLocation();
  const { updateColor } = useThemeColor();
  const { currentMap, journeyMapView } = useJourney();
  const { data: favorites } = useUserFavoriteCareers();

  useDoSync();

  const routeClassName = useMemo(
    () => location.state?.className ?? "map",
    [location],
  );

  useEffect(() => {
    if (routeClassName !== "map") {
      updateColor(themeColors[routeClassName]);
    }
  }, [routeClassName]);

  return (
    <div
      className={clsx(
        "tx-app-layout",
        `page-${routeClassName}`,
        `map-${currentMap}`,
        "map-3d-enabled",
        journeyMapView && "map-view",
      )}
    >
      {user.claims.impersonatorId && <ImpersonationBar user={user} />}
      <Navbar>
        <NavbarItem
          title="Explore"
          icon="signs-post"
          to={AppRoutes.journeyMap}
          state={{ className: "map" }}
        />
        <NavbarItem
          title="Favs"
          icon="stars"
          to={AppRoutes.favorites}
          state={{ className: "favorites" }}
          badge={favorites?.length > 0 && <Badge>{favorites.length}</Badge>}
        />
        <ProfileItemNavbar
          title="Profile"
          position="bottom"
          to={AppRoutes.profile}
          state={{ className: "profile" }}
          user={user}
        />
      </Navbar>
      <div className="outlet">
        <Outlet />
      </div>
    </div>
  );
};
