import clsx from "clsx";
import { AnimatePresence, motion } from "framer-motion";
import PropTypes from "prop-types";
import {
  Children,
  createContext,
  useEffect,
  useId,
  useMemo,
  useRef,
  useState,
} from "react";
import { focusable } from "tabbable";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { EmptyArray } from "@lib/app.helpers";
import { buttonTracking } from "@lib/tracking.helpers";

import "./slideshow.scss";

export const SlideshowContext = createContext();

export const SlideDirection = {
  right: 1,
  left: -1,
};

export const Slideshow = ({
  index = 0,
  numberSlides,
  onNextSlide,
  onPreviousSlide,
  onSlideChanged,
  controlsEnabled,
  indicatorEnabled,
  transitionDuration = 0.3,
  children,
  className,
}) => {
  const slideshowId = useId();

  const focusButtonRef = useRef();
  const nextButtonRef = useRef();
  const prevButtonRef = useRef();
  const focusNextElementRef = useRef(false);

  const indexRef = useRef(index);
  const elementRef = useRef();
  const [movement, setMovement] = useState();

  const slides = Children.toArray(children);
  const [slideIndex, setSlideIndex] = useState(0);

  const totalSlides = useMemo(
    () => numberSlides ?? slides.length,
    [numberSlides, slides],
  );

  const { nextEnabled, previousEnabled } = useMemo(() => {
    const nextEnabled = controlsEnabled && index + 1 < totalSlides;
    const previousEnabled = controlsEnabled && index > 0;

    return { nextEnabled, previousEnabled };
  }, [controlsEnabled, index, totalSlides]);

  useEffect(() => {
    if (focusButtonRef.current === "next" && !nextEnabled) {
      focusButtonRef.current = undefined;
      focusNextElementRef.current = true;
    } else if (focusButtonRef.current === "prev" && !previousEnabled) {
      focusButtonRef.current = undefined;
      focusNextElementRef.current = true;
    }
  }, [previousEnabled, nextEnabled]);

  useEffect(() => {
    const { current: previousIndex } = indexRef;
    const { width } = elementRef.current?.getBoundingClientRect() ?? 0;
    const direction =
      previousIndex > index ? SlideDirection.right : SlideDirection.left;
    indexRef.current = index;
    setMovement({ direction, value: width });
    setSlideIndex(index);
  }, [index]);

  const focusNextElementIfNecessary = () => {
    if (focusNextElementRef.current) {
      const [nextElement] = focusable(elementRef.current);
      nextElement?.focus();
      focusNextElementRef.current = false;
    }
  };

  const onExitComplete = () => {
    // This fires before the exited element has been unmounted. If we try to
    // find the next element right now then we will find the first focusable
    // element in the slide that is going away, so we need to wait a tick so
    // we can get the first element in the new slide.
    setTimeout(() => {
      focusNextElementIfNecessary();
      onSlideChanged?.({ index, totalSlides });
    }, 0);
  };

  return (
    <SlideshowContext.Provider value={{ movement, transitionDuration }}>
      <motion.div
        className={clsx("tx-slideshow", className)}
        id={slideshowId}
        role="region"
        aria-roledescription="carousel"
        aria-label="related simulations"
        aria-live={controlsEnabled ? "polite" : "off"}
      >
        {previousEnabled && (
          <button
            ref={prevButtonRef}
            className="slideshow-button previous"
            onClick={onPreviousSlide}
            onFocus={() => (focusButtonRef.current = "prev")}
            aria-controls={slideshowId}
            aria-label="Previous Slide"
            {...buttonTracking("slideshow", "previous")}
          >
            <FontAwesomeIcon icon={["fa-regular", "arrow-left"]} />
          </button>
        )}
        <div
          ref={elementRef}
          className="slideshow-container"
          role="group"
          aria-roledescription="slide"
          aria-label={`${slideIndex + 1} of ${slides.length}`}
        >
          <AnimatePresence
            initial={false}
            mode="popLayout"
            onExitComplete={onExitComplete}
          >
            {slides[slideIndex]}
          </AnimatePresence>
        </div>
        {nextEnabled && (
          <button
            ref={nextButtonRef}
            className="slideshow-button next"
            onClick={onNextSlide}
            onFocus={() => (focusButtonRef.current = "next")}
            aria-controls={slideshowId}
            aria-label="Next Slide"
            {...buttonTracking("slideshow", "next")}
          >
            <FontAwesomeIcon icon={["fa-regular", "arrow-right"]} />
          </button>
        )}
      </motion.div>
      {indicatorEnabled && totalSlides > 1 && (
        <div className="slide-indicators">
          <ul>
            {EmptyArray(totalSlides).map((_, i) => (
              <li
                key={`indicator.${i}`}
                className={clsx("slide-indicator", i == index && "selected")}
              ></li>
            ))}
          </ul>
        </div>
      )}
    </SlideshowContext.Provider>
  );
};

Slideshow.displayName = "Slideshow";

Slideshow.propTypes = {
  index: PropTypes.number,
  numberSlides: PropTypes.number,
  controlsEnabled: PropTypes.bool,
  indicatorEnabled: PropTypes.bool,
  onNextSlide: PropTypes.func,
  onPreviousSlide: PropTypes.func,
  onSlideChanged: PropTypes.func,
  transitionDuration: PropTypes.number,
  className: PropTypes.string,
  children: PropTypes.node,
};
