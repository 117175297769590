/* eslint react/no-unknown-property: 0 */

import { useTexture } from "@react-three/drei";
import PropTypes from "prop-types";
import { useEffect, useMemo, useRef, useState } from "react";

import lock from "@images/journey-map/lock.png";
import {
  Blueberries,
  DashTheHedgehog,
  PineCones,
  Shire,
} from "@pages/journey-map/3d-models";
import {
  JourneyButton3D,
  JourneyMapZPositionedGroup,
  JourneyPin,
  JourneyTitle3D,
  Overlay,
} from "@pages/journey-map/components";
import { NonBlockingA11y } from "@pages/journey-map/components/non-blocking.a11y";

import { useJourneySteps } from "@pages/journey-map/api/use-journey-steps";
import { useUserJourney } from "@pages/journey-map/api/use-user-journey";
import {
  dashAnimations,
  useDashIdleDetection,
  useDashTheHedgehog,
  useHoverAnimation,
} from "@pages/journey-map/hooks";
import { useIntro } from "@pages/journey-map/hooks/use-intro";

import { noPropagationHandler } from "@lib/app.helpers";

import "./map-four.scss";

const dashInitialPosition = [170, 0, -350];
const introInitialPosition = [60, 0, -325];
const goalsPathPosition = [175, 0, -290];
const sharePathPosition = [-120, 0, 190];

const introPathPoints = [introInitialPosition, dashInitialPosition];

const mainPathPoints = [
  dashInitialPosition,
  goalsPathPosition,
  [170, 0, -250],
  [110, 0, -150],
  [130, 0, -90],
  [140, 0, -50],
  [135, 0, -30],
  [90, 0, 50],
  [20, 0, 80],
  [-70, 0, 110],
  [-120, 0, 140],
  sharePathPosition,
];

export const MapFour = ({
  isPlanShared,
  unlockedSteps,
  onMoveToStep,
  triggerMapAnimation,
}) => {
  const dashRef = useRef();
  const [activeSpeech, setActiveSpeech] = useState();

  const blueberriesHoverAnimation = useHoverAnimation({ enabled: false });
  const pineconesHoverAnimation = useHoverAnimation();

  const { data: journeySteps } = useJourneySteps();
  const { data: userJourney } = useUserJourney();

  const [showTooltip, setShowTooltip] = useState(false);

  const textures = useTexture({
    lock,
  });

  const isShareLocked = !unlockedSteps[journeySteps.stepsByCode.SHARE.stepNum];

  const shareTooltipContent = useMemo(
    () =>
      isShareLocked
        ? "Set up goals for your pathway before sharing."
        : "We've shared your plan!",
    [isShareLocked],
  );

  useEffect(() => {
    if (!isShareLocked) {
      setShowTooltip(false);
      blueberriesHoverAnimation.enabled(true);
    }
  }, [isShareLocked]);

  const { closeIntro, dashProps, speech, viewedIntro } = useIntro({
    introKey: "map4",
    initialPosition: dashInitialPosition,
    introInitialPosition,
    introPathPoints,
    mainPathPoints,
    text: "Almost home! Let's set some goals before a well deserved rest.",
    pinConfig: {
      position: [-15, -10, 0],
      size: [240, 50],
    },
  });
  const dash = useDashTheHedgehog({
    ref: dashRef,
    ...dashProps,
  });
  const { idleSpeech, stopIdleDetection } = useDashIdleDetection({
    onIdle: () =>
      isShareLocked
        ? pineconesHoverAnimation.getAttention()
        : blueberriesHoverAnimation.getAttention(),
    speech: {
      animationDuration: 5000,
      text: isShareLocked
        ? "Let's setup goals for your new hedge fund!"
        : "It's berry important you share your next steps.",
      pinConfig: {
        size: [140, 40],
        fontSize: 10,
      },
    },
    enabled: !!viewedIntro,
  });

  useEffect(() => {
    if (!viewedIntro) {
      setActiveSpeech(speech);
    } else if (isPlanShared || showTooltip) {
      setActiveSpeech({
        animationDuration: 5000,
        text: shareTooltipContent,
        textDuration: 10_000,
        pinConfig: {
          size: isPlanShared ? [90, 40] : [150, 40],
          fontSize: 10,
        },
      });
    }
  }, [speech, viewedIntro, isPlanShared, showTooltip, isShareLocked]);

  const openShareDialog = noPropagationHandler(() => {
    if (isShareLocked) {
      setShowTooltip((v) => !v);
    } else {
      stopIdleDetection();
      dash.setDestination({
        distance: 1,
        onDestinationReached: () =>
          onMoveToStep(journeySteps.stepsByCode.SHARE),
      });
    }
  });

  const onPineConesClick = noPropagationHandler(() => {
    stopIdleDetection();
    dash.setDestination({
      distance:
        mainPathPoints.indexOf(goalsPathPosition) / (mainPathPoints.length - 1),
      onDestinationReached: () => onMoveToStep(journeySteps.stepsByCode.GOALS),
    });
  });

  useEffect(() => {
    if (
      viewedIntro &&
      !isShareLocked &&
      triggerMapAnimation === journeySteps.stepsByCode.SHARE.stepNum
    ) {
      openShareDialog();
    }
  }, [isShareLocked, triggerMapAnimation, viewedIntro]);

  return (
    <JourneyMapZPositionedGroup>
      <Overlay enabled={!viewedIntro} onClick={closeIntro} />
      <group position={[0, 49, -20]} rotation={[0, Math.PI * -0.05, 0]}>
        <DashTheHedgehog
          rotation={[0, Math.PI * 0.3, 0]}
          scale={70}
          animation={{ name: dashAnimations.Relaxed }}
        />

        <DashTheHedgehog
          position={[20, 0, 0]}
          rotation={[0, Math.PI * -0.2, 0]}
          scale={50}
          animation={{ name: dashAnimations.RelaxedTwo }}
        />
      </group>

      <DashTheHedgehog
        position={[-40, 30, -440]}
        rotation={[0, Math.PI * 0.1, 0]}
        scale={35}
        animation={{ name: dashAnimations.Relaxed }}
      />

      <DashTheHedgehog
        ref={dashRef}
        position={dash.position}
        scale={80}
        animation={dash.animation}
        onActionFinished={dash.onAnimationFinished}
        speech={activeSpeech ?? idleSpeech}
        outfit={["fannyPack"]}
      />

      <JourneyTitle3D
        position={[-135, 80, -200]}
        rotation={[Math.PI * -0.14, Math.PI * 0.08, Math.PI * 0.008]}
        header="Pathway:"
        subheader={userJourney.currentJourneyCareer?.title}
        padding={5}
        fontSize={16}
        maxWidth={200}
        color="#68813c"
        fontColor="white"
      />

      <NonBlockingA11y
        role="button"
        description="set some goals for your selected career"
        actionCall={onPineConesClick}
        active={viewedIntro}
      >
        <JourneyButton3D
          position={[200, 0, -300]}
          hoverAnimation={pineconesHoverAnimation}
          onClick={onPineConesClick}
        >
          <JourneyPin
            size={[45, 20]}
            padding={5}
            position={[0, 60, 0]}
            rotation={[-0.4, -0.2, 0]}
            text="Goals"
            fontSize={12}
          />
          <PineCones scale={120} />
        </JourneyButton3D>
      </NonBlockingA11y>

      <NonBlockingA11y
        role="button"
        description="share your goals"
        actionCall={openShareDialog}
        active={viewedIntro}
      >
        <JourneyButton3D
          position={[-120, 0, 170]}
          onClick={openShareDialog}
          hoverAnimation={blueberriesHoverAnimation}
        >
          <JourneyPin
            size={[isShareLocked ? 50 : 40, 20]}
            padding={5}
            position={[0, 60, 0]}
            rotation={[-0.4, 0.3, 0]}
            text="Share"
            fontSize={10}
            iconTexture={isShareLocked ? textures.lock : undefined}
            enabled={!isShareLocked}
          />
          <Blueberries
            scale={100}
            rotation={[0, Math.PI * 0.2, 0]}
            hoverAnimation={blueberriesHoverAnimation}
          />
        </JourneyButton3D>
      </NonBlockingA11y>

      <Shire
        position={[20, 0, 130]}
        scale={100}
        rotation={[0, Math.PI * 0.51, 0]}
      />
    </JourneyMapZPositionedGroup>
  );
};

MapFour.propTypes = {
  isPlanShared: PropTypes.bool,
  unlockedSteps: PropTypes.arrayOf(PropTypes.bool),
  onMoveToStep: PropTypes.func,
  triggerMapAnimation: PropTypes.number,
};
