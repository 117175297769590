import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import { motion } from "framer-motion";
import PropTypes from "prop-types";
import { forwardRef, useState } from "react";
import Skeleton from "react-loading-skeleton";

import { ButtonVariants } from "@lib/animation.helpers";
import { buttonTracking } from "@lib/tracking.helpers";

import "./question.scss";

export const Question = forwardRef(
  ({ children, imageUrl, text, onAnswer }, elementRef) => {
    const [loading, setLoading] = useState(true);

    return (
      <motion.div
        ref={elementRef}
        className="question"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        role="radiogroup"
        aria-label={text}
      >
        <motion.img
          aria-hidden
          src={imageUrl}
          onLoad={() => setLoading()}
          alt={text}
          animate={{ scale: loading ? 0 : 1 }}
          className={clsx(loading && "loading")}
        />
        {loading && (
          <Skeleton
            height="inherit"
            width="60%"
            circle
            containerClassName="tx-skeleton-container"
          />
        )}
        {children}
        <h1 aria-hidden>{text}</h1>
        <div className="answer-container">
          <motion.button
            role="radio"
            aria-label="No"
            variants={ButtonVariants}
            whileHover="hover"
            whileTap="tap"
            className="negative"
            onClick={() => onAnswer(false)}
            {...buttonTracking("interest-inventory", "answer", "no")}
          >
            <FontAwesomeIcon icon={["fasr", "thumbs-down"]} />
          </motion.button>
          <motion.button
            role="radio"
            aria-label="Yes"
            variants={ButtonVariants}
            whileHover="hover"
            whileTap="tap"
            className="affirmative"
            onClick={() => onAnswer(true)}
            {...buttonTracking("interest-inventory", "answer", "yes")}
          >
            <FontAwesomeIcon icon={["fasr", "thumbs-up"]} />
          </motion.button>
        </div>
      </motion.div>
    );
  },
);

Question.displayName = "Question";

Question.propTypes = {
  children: PropTypes.node,
  imageUrl: PropTypes.string,
  text: PropTypes.string,
  onAnswer: PropTypes.func,
};
