/* eslint react/no-unknown-property: 0 */

import PropTypes from "prop-types";
import { forwardRef, useMemo } from "react";
import { useSearchParams } from "react-router-dom";

import { JourneyMapView as JourneyMapView2D } from "./2D/journey-map.view";
import { JourneyMapView as JourneyMapView3D } from "./3D/journey-map.view";

import { strToBool } from "@lib/app.helpers";

const map3DQueryParam = "map-3d";

export const JourneyMap = forwardRef(
  (
    {
      pageHeight,
      currentMap,
      triggerMapAnimation,
      moveToMap,
      moveToStep,
      onMapAnimationComplete,
      unlockedSteps,
      isPlanShared,
    },
    ref,
  ) => {
    const [queryParams] = useSearchParams();

    // Enable/disable 3D map through a query param
    const map3DEnabled = useMemo(
      () => strToBool(queryParams.get(map3DQueryParam) ?? "true"),
      [queryParams],
    );

    return map3DEnabled ? (
      <JourneyMapView3D
        ref={ref}
        pageHeight={pageHeight}
        currentMap={currentMap}
        triggerMapAnimation={triggerMapAnimation}
        moveToMap={moveToMap}
        moveToStep={moveToStep}
        onMapAnimationComplete={onMapAnimationComplete}
        unlockedSteps={unlockedSteps}
        isPlanShared={isPlanShared}
      />
    ) : (
      <JourneyMapView2D
        ref={ref}
        pageHeight={pageHeight}
        currentMap={currentMap}
        triggerMapAnimation={triggerMapAnimation}
        moveToMap={moveToMap}
        moveToStep={moveToStep}
        onMapAnimationComplete={onMapAnimationComplete}
        unlockedSteps={unlockedSteps}
        isPlanShared={isPlanShared}
      />
    );
  },
);

JourneyMap.displayName = "Journey Map View";
JourneyMap.propTypes = {
  pageHeight: PropTypes.number,
  currentMap: PropTypes.number,
  triggerMapAnimation: PropTypes.number,
  moveToMap: PropTypes.func,
  moveToStep: PropTypes.func,
  onMapAnimationComplete: PropTypes.func,
  unlockedSteps: PropTypes.array,
  isPlanShared: PropTypes.bool,
};
